import { ForumPage } from '../../components/ForumPage';
import { groupUrltoId } from '../../helpers/groupUrltoId';

export const Challenges = ({ handleNewPostCount }) => {

    const groupId = groupUrltoId['/challenges'];

    return (
        <ForumPage title="Challenges" icon="🟡" groupId={groupId} path="/challenges" handleNewPostCount={handleNewPostCount} />
    )

};