import { ForumPage } from '../../components/ForumPage';
import { groupUrltoId } from '../../helpers/groupUrltoId';

export const SlifePolicy = ({ handleNewPostCount }) => {

    const groupId = groupUrltoId['/slife-policy'];

    return (
        <ForumPage title="SLIFE and Newcomer Policy" icon="🟡" groupId={groupId} path="/slife-policy" handleNewPostCount={handleNewPostCount} />
    )

};