export const groupUrltoId = {
    "/slife-policy": "f901ab32-4fce-48cb-ba7e-3ccd7aac52e6",
    "/ask-inlier": "c8af86a9-aa4b-482d-a1ac-3359464f1554",
    "/assessment": "44cbe878-0ed1-48e7-8875-53eb95a62a68",
    "/identification": "dbccc687-da95-4755-8e2b-5cbde8f93279",
    "/special-education": "6a0003e9-9e6e-4c4a-a73a-d251158e46bc",
    "/math": "fee201c3-d56e-4dc1-9f09-ac2115bddeb6",
    "/reading": "d972ed79-0fa7-41a9-a1fd-f05d36bc850a",
    "/trauma-support": "404c1eb4-4c25-44e4-bfcb-c120088875da",
    "/behavioral-support": "3368a39c-7313-4e52-8617-0c9cfa34f5e1",
    "/grading": "ee02ef67-b6c0-4526-aba8-4b24b09c2274",
    "/wins": "8d23bdeb-5fbc-4d0c-a657-7a4cde699ef0",
    "/challenges": "2a70ac11-250d-47d7-91fd-cd1758287c96",
    "/state/connecticut": "054f0ec9-5ad3-4206-8970-b3c57c259c91",
    "/state/idaho": "245feadc-b1cd-4675-bf9b-5c615844524d",
    "/state/maryland": "54bd5ccd-021d-4e68-afc8-4d44403240b4",
    "/state/massachusetts": "93d10e17-844d-4e94-9d85-606d57c3f0c9",
    "/state/newhampshire": "30096df6-6d49-4b89-9e05-82221c525052",
    "/announcements": "5d20e7ca-771e-4ec6-ba70-70ad52d1a1fc"
}




// "c9f3748a-94b1-4836-a47f-c885931735aa"	"Stay Connected; Upcoming Events"
// "5d20e7ca-771e-4ec6-ba70-70ad52d1a1fc"	"Stay Connected; Announcements"
// "2f3e2771-ff6f-43e2-bdde-64612e880092"	"Stay Connected; Past Events"
// "04e36692-6f4e-478a-a7f9-01202d06b87b"	"Stay Connected; Office Hours"
// "c8af86a9-aa4b-482d-a1ac-3359464f1554"   "Community Thinktank; Ask Inlier"
// "f901ab32-4fce-48cb-ba7e-3ccd7aac52e6"	"Community Thinktank; SLIFE and Newcomer Policy"
// "44cbe878-0ed1-48e7-8875-53eb95a62a68"	"Community Thinktank; Assessment"
// "dbccc687-da95-4755-8e2b-5cbde8f93279"	"Community Thinktank; Identification"
// "6a0003e9-9e6e-4c4a-a73a-d251158e46bc"	"Community Thinktank; Special Education"
// "fee201c3-d56e-4dc1-9f09-ac2115bddeb6"	"Community Thinktank; Math"
// "d972ed79-0fa7-41a9-a1fd-f05d36bc850a"	"Community Thinktank; Reading"
// "404c1eb4-4c25-44e4-bfcb-c120088875da"	"Community Thinktank; Trauma Support"
// "3368a39c-7313-4e52-8617-0c9cfa34f5e1"	"Community Thinktank; Behavioral Support"
// "8d23bdeb-5fbc-4d0c-a657-7a4cde699ef0"	"For You; Wins"
// "2a70ac11-250d-47d7-91fd-cd1758287c96"	"For You; Challenges"
// "ee02ef67-b6c0-4526-aba8-4b24b09c2274"	"Community Thinktank; Grading"
// "054f0ec9-5ad3-4206-8970-b3c57c259c91"	"Stay Connected; Connecticut"
// "011649dd-5b0c-4592-9a70-dfccf149c913"	"Stay Connected; Maine"
// "245feadc-b1cd-4675-bf9b-5c615844524d"	"Stay Connected; Idaho"
// "93d10e17-844d-4e94-9d85-606d57c3f0c9"	"Stay Connected; Massachusetts"
// "30096df6-6d49-4b89-9e05-82221c525052"	"Stay Connected; New Hampshire"
// "54bd5ccd-021d-4e68-afc8-4d44403240b4"	"Stay Connected; Maryland"