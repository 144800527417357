import { ForumPage } from '../../components/ForumPage';
import { groupUrltoId } from '../../helpers/groupUrltoId';

export const BehavioralSupport = ({ handleNewPostCount }) => {

    const groupId = groupUrltoId['/behavioral-support'];

    return (
        <ForumPage title="Behavioral Support" icon="🟡" groupId={groupId} path="/behavioral-support" handleNewPostCount={handleNewPostCount} />
    )

};