import { api } from './axios';
import { checkAndRefreshToken } from './checkAndRefreshToken';

export const videosApiHelpers = {
    // Fetch Signed URL for the Video
    fetchSignedUrl: async (fileName) => {
        const isTokenValid = await checkAndRefreshToken();
        const accessToken = window.localStorage.getItem("iat");

        if (!isTokenValid || !accessToken) {
            throw new Error('Unable to authenticate');
        }

        const response = await api.get(`/videos/signed-url?fileName=${encodeURIComponent(fileName)}`, {
            withCredentials: true,
            headers: {
                authorization: `Bearer ${accessToken}`
            }
        });

        return response.data.message.signedUrl;
    }
};
