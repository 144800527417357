import { api } from './axios';
import { checkAndRefreshToken } from './checkAndRefreshToken';

export const postsApiHelpers = {
    fetchPosts: async (groupId) => {
        const isTokenValid = await checkAndRefreshToken();
        const accessToken = window.localStorage.getItem("iat");

        if (!isTokenValid || !accessToken) {
            throw new Error('Unable to authenticate');
        }

        const response = await api.get(`/posts/group/${groupId}`, {
            withCredentials: true,
            headers: {
                "Content-type": "application/json",
                authorization: `Bearer ${accessToken}`
            }
        });
        return response.data.message;
    },

    createPost: async (newPost) => {
        const isTokenValid = await checkAndRefreshToken();
        const accessToken = window.localStorage.getItem("iat");

        if (!isTokenValid || !accessToken) {
            throw new Error('Unable to authenticate');
        }

        const response = await api.post('/posts', newPost, {
            withCredentials: true,
            headers: {
                Accept: "application/json",
                authorization: `Bearer ${accessToken}`
            }
        });
        return response.data.message;
    },

    // New: Update Post
    updatePost: async (postId, updatedPost) => {
        const isTokenValid = await checkAndRefreshToken();
        const accessToken = window.localStorage.getItem("iat");

        if (!isTokenValid || !accessToken) {
            throw new Error('Unable to authenticate');
        }

        const response = await api.put(`/posts/${postId}`, updatedPost, {
            withCredentials: true,
            headers: {
                "Content-type": "application/json",
                authorization: `Bearer ${accessToken}`
            }
        });
        return response.data.message;
    },

    // New: Delete Post
    deletePost: async (postId) => {
        const isTokenValid = await checkAndRefreshToken();
        const accessToken = window.localStorage.getItem("iat");

        if (!isTokenValid || !accessToken) {
            throw new Error('Unable to authenticate');
        }

        const response = await api.delete(`/posts/${postId}`, {
            withCredentials: true,
            headers: {
                authorization: `Bearer ${accessToken}`
            }
        });
        return response.data.message;
    },

    // New: Fetch New Posts Count
    fetchNewPostsCount: async () => {
        const isTokenValid = await checkAndRefreshToken();
        const accessToken = window.localStorage.getItem("iat");

        if (!isTokenValid || !accessToken) {
            throw new Error('Unable to authenticate');
        }

        try {
            const response = await api.get("/posts/new-posts-count", {
                withCredentials: true,
                headers: {
                    authorization: `Bearer ${accessToken}`
                }
            });
            return response.data.message;  // Return the newPosts array
        } catch (err) {
            console.error("Failed to fetch new posts count:", err);
            throw err;
        }
    }
};
