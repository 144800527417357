export const initialViews = [
    {
        subheader: "Stay Connected",
        items: [
            { text: "Start Here", icon: "🚀", path: "/start-here" },
            { text: "Upcoming Events", icon: "🔖", path: "/upcoming-events" },
            { text: "Past Events", icon: "⏮️", path: "/past-events" },
            { text: "Announcements", icon: "💬", path: "/announcements" },
            { text: "Office Hours", icon: "📅", path: "/office-hours" },
        ]
    },
    {
        subheader: "Toolkits",
        items: [
            { text: "ABEE", icon: "🛠️", path: "/abee" }
        ]
    },
    {
        subheader: "Community Thinktank",
        items: [
            { text: "Ask Inlier", icon: "🟡", path: "/ask-inlier" },
            { text: "SLIFE and Newcomer Policy", icon: "🟡", path: "/slife-policy" },
            { text: "Assessment", icon: "🟡", path: "/assessment" },
            { text: "Identification", icon: "🟡", path: "/identification" },
            { text: "Special Education", icon: "🟡", path: "/special-education" },
            { text: "Math", icon: "🟡", path: "/math" },
            { text: "Reading", icon: "🟡", path: "/reading" },
            { text: "Trauma Support", icon: "🟡", path: "/trauma-support" },
            { text: "Behavioral Support", icon: "🟡", path: "/behavioral-support" },
            { text: "Grading", icon: "🟡", path: "/grading" }
        ]
    },
    {
        subheader: "For You",
        items: [
            { text: "Wins", icon: "🔵", path: "/wins" },
            { text: "Challenges", icon: "🔵", path: "/challenges" }
        ]
    },
    {
        subheader: "Links",
        items: [
            { text: "Feedback and Requests", icon: "🟢", path: "/feedback" },
            { text: "Help Center", icon: "🟢", path: "/help-center" },
        ]
    }
];
