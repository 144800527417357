import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    typography: {
        fontFamily: "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif",
        fontSize: 14,
        fontWeight: 300,
        lineHeight: '20px',
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    margin: 0,
                    fontFamily: "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif",
                    lineHeight: 1.5,
                    height: '100%',
                    width: '100%',
                },
                h1: {
                    fontFamily: "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif",
                    fontWeight: 500,
                },
                h3: {
                    fontFamily: "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif",
                    fontWeight: 500,
                },
                h4: {
                    fontFamily: "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif",
                    fontWeight: 500,
                },
                a: {
                    fontSize: '14px',
                    fontWeight: 400,
                    padding: '0.375rem 1rem',
                    display: 'flex',
                    gap: '0.5rem',
                    alignItems: 'center',
                    '&:hover': {
                        backgroundColor: 'var(--color-background-tertiary)',
                    },
                },
                'a.gi-link': {
                    fontSize: '14px',
                    fontWeight: 400,
                    padding: '0.375rem 1rem',
                    display: 'flex',
                    gap: '0.5rem',
                    alignItems: 'center',
                    '&:hover': {
                        backgroundColor: 'var(--color-background-tertiary)',
                    },
                },
                button: {
                    textTransform: 'none',
                    fontFamily: 'inherit',
                    fontSize: '100%',
                    fontWeight: 'inherit',
                    lineHeight: 'inherit',
                    color: 'inherit',
                    margin: 0,
                    padding: 0,
                    '-webkit-appearance': 'button',
                    backgroundColor: 'transparent',
                    backgroundImage: 'none',
                },
                ul: {
                    listStyleType: 'disc',
                    listStylePosition: 'inside',
                },
                input: {
                    fontSize: '1rem',
                    '&::placeholder': {
                        opacity: 0.5,
                    },
                },
            },
        },
        MuiList: {
            styleOverrides: {
                root: {
                    color: '#545861',
                    fontSize: '14px',
                },
            },
        },
        MuiListItem: {
            styleOverrides: {
                root: {
                    color: '#545861',
                    fontSize: '14px',
                },
            },
        },
        MuiListItemText: {
            styleOverrides: {
                primary: {
                    color: '#545861',
                    fontSize: '14px',
                },
            },
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: '#545861',
                    fontSize: '14px',
                },
            },
        },
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    color: '#545861',
                    fontSize: '14px',
                    paddingTop: '2px',
                    paddingBottom: '2px',
                    marginTop: '2px',
                    marginBottom: '2px',
                },
            },
        },
        MuiListSubheader: {
            styleOverrides: {
                root: {
                    fontWeight: 'bold',
                    fontSize: '14px',
                    color: '#545861',
                    lineHeight: '1.25',
                    textTransform: 'uppercase',
                    paddingLeft: '0px !important',
                    paddingRight: '0px !important',
                    marginTop: '12px',  // Add more margin to the top of subheader buttons
                    marginBottom: '6px',  // Add more margin to the bottom of subheader buttons
                },
            },
        },
    },
});

export default theme;
