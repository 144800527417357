import { ForumPage } from '../../components/ForumPage';
import { groupUrltoId } from '../../helpers/groupUrltoId';

export const SpecialEducation = ({ handleNewPostCount }) => {

    const groupId = groupUrltoId['/special-education'];

    return (
        <ForumPage title="Special Education" icon="🟡" groupId={groupId} path="/special-education" handleNewPostCount={handleNewPostCount} />
    )

};