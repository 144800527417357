import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
    Grid,
    Paper,
    TextField,
    Button,
    Typography,
    Link,
    Box,
    IconButton,
    InputAdornment
} from "@mui/material";
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { toast } from "react-toastify";
import { api } from "../helpers/axios";

// Password validation function
const validatePassword = (password) => {
    const minLength = password.length >= 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    return minLength && hasUpperCase && hasLowerCase && hasSpecialChar;
};

export const SetPassword = () => {
    const { userId, token } = useParams();  // Destructure userId and token from URL params
    const navigate = useNavigate();

    const [password, setPassword] = useState("");
    const [passwordMatch, setPasswordMatch] = useState("");
    const [success, setSuccess] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [errorText2, setErrorText2] = useState('');
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    // Enable button when password and passwordMatch are valid
    useEffect(() => {
        if (validatePassword(password) && password === passwordMatch) {
            setButtonDisabled(false);
        } else {
            setButtonDisabled(true);
        }
    }, [password, passwordMatch]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (password !== passwordMatch) {
            toast.error("Passwords don't match.");
            return;
        }

        if (!validatePassword(password)) {
            toast.error("Password does not meet the required criteria.");
            return;
        }

        try {
            const response = await api.post(`/auth/setpassword/${userId}/${token}`, { password });
            if (response && response.data && response.data.status === 200) {
                setSuccess(true);
            }
        } catch (err) {
            if (err.response && err.response.data.message === "Error: Invalid link or expired") {
                toast.error("The link is invalid or has expired. Please request a new password reset.");
                navigate("/");  // Navigate to login page after the error toast
            } else {
                toast.error("Cannot set new password. Please try again later.");
            }
        }
    };

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: "4rem",
        }}>
            <Grid>
                <Paper
                    elevation={3}
                    sx={{ padding: "2rem", minWidth: "300px", maxWidth: "400px" }}>  {/* Fix card size */}
                    <Grid align="center">
                        <Typography component="h6">
                            Set New Password
                        </Typography>
                    </Grid>
                    {!success ? (
                        <div>
                            <Typography>Type new password</Typography>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="password"
                                label="New Password"
                                name="password"
                                type={showPassword ? "text" : "password"}
                                autoFocus
                                onChange={(e) => setPassword(e.target.value)}
                                onBlur={() => {
                                    if (!validatePassword(password)) {
                                        setErrorText('Password must be at least 8 characters long, contain uppercase and lowercase letters, and include at least one special character.');
                                    } else {
                                        setErrorText('');
                                    }
                                }}
                                error={errorText.length > 0}
                                helperText={errorText}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => setShowPassword(!showPassword)}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                sx={{ mb: 2, minHeight: '80px' }}
                            />
                            <Typography style={{ paddingTop: "1.5em" }}>
                                Confirm your password
                            </Typography>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="passwordMatch"
                                label="Confirm New Password"
                                name="passwordMatch"
                                type={showConfirmPassword ? "text" : "password"}
                                onChange={(e) => setPasswordMatch(e.target.value)}
                                onBlur={() => {
                                    if (passwordMatch !== password) {
                                        setErrorText2('Passwords do not match.');
                                    } else {
                                        setErrorText2('');
                                    }
                                }}
                                error={errorText2.length > 0}
                                helperText={errorText2}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                                edge="end"
                                            >
                                                {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                sx={{ minHeight: '80px' }}
                            />
                            <Button
                                disabled={buttonDisabled}
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                                onClick={handleSubmit}
                            >
                                Set Password
                            </Button>
                        </div>
                    ) : (
                        <div>
                            <Typography>
                                Success! You can now sign in with your new password.
                            </Typography>
                            <Button
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                                onClick={() => navigate("/")}
                            >
                                Login
                            </Button>
                        </div>
                    )}
                    <div>
                        For any further questions, click "Request Help" to contact us.
                        <div>
                            <Link href="https://forms.gle/P7bnCjEFwLrLxrmr8" target="_blank" rel="noreferrer">
                                Request Help
                            </Link>
                        </div>
                    </div>
                </Paper>
            </Grid>
        </Box>
    );
};
