import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

export const ProtectedRoute = ({ accessToken }) => {
    const location = useLocation();

    // If there's no access token, redirect to login page
    if (!accessToken) {
        // Redirect to the login page and preserve the original path
        return <Navigate to={`/?redirect=${location.pathname}`} />;
    }

    // Otherwise, render the child routes (protected content)
    return <Outlet />;
};
