import React from 'react';
import { List, Typography } from '@mui/material';
import { UpcomingEventListCard, UpcomingEventListInnerBox } from '../../styles/useStyles';
import { EventItem } from './EventItem';

export const EventList = ({ monthData, expandedEventIndex, handleToggle }) => {
    // Ensure monthData and events exist
    if (!monthData || !Array.isArray(monthData.events) || monthData.events.length === 0) {
        return null; // Or return a message if there are no events
    }

    const totalEvents = monthData.events.length; // Get total number of events

    return (
        <UpcomingEventListInnerBox>
            <Typography variant="h6" gutterBottom>
                {monthData.month}
            </Typography>
            <UpcomingEventListCard>
                <List>
                    {monthData.events.map((event, eventIndex) => (
                        <EventItem
                            key={eventIndex}
                            event={event}
                            monthIndex={monthData.monthIndex}
                            eventIndex={eventIndex}
                            expandedEventIndex={expandedEventIndex}
                            handleToggle={handleToggle}
                            totalEvents={totalEvents} // Pass totalEvents to EventItem
                        />
                    ))}
                </List>
            </UpcomingEventListCard>
        </UpcomingEventListInnerBox>
    );
};
