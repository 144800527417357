export const groupNametoPath = {
    "Wins": "/wins",
    "Challenges": "/challenges",
    "SLIFE and Newcomer Policy": "/slife-policy",
    "Assessment": "/assessment",
    "Identification": "/identification",
    "Special Education": "/special-education",
    "Announcements": "/announcements",
    "Connecticut": "/state/connecticut",
    "Idaho": "/state/idaho",
    "Maryland": "/state/maryland",
    "Massachusetts": "/state/massachusetts",
    "New Hampshire": "/state/newhampshire",
    "Math": "/math",
    "Reading": "/reading",
    "Trauma Support": "/trauma-support",
    "Behavioral Support": "/behavioral-support",
    "Grading": "/grading",
    "Ask Inlier": "/ask-inlier",
}