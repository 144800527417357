import { ForumPage } from '../components/ForumPage';
import { groupUrltoId } from '../helpers/groupUrltoId';

export const Announcements = ({ handleNewPostCount }) => {
    const groupId = groupUrltoId['/announcements'];  // Make sure this key exists in groupUrltoId

    return (
        <ForumPage title="Announcements" icon="💬" groupId={groupId} path="/announcements" defaultCanPost={false} handleNewPostCount={handleNewPostCount} />
    );
};
