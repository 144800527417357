import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.core.css';
import { commentsApiHelpers } from '../../helpers/commentsApiHelpers';
import { sanitizeLink } from '../../helpers/sanitizeLink';
import { StyledCommentReactQuill, StyledCommentButtonContainer, StyledCommentButton } from '../../styles/useStyles';

export const CommentInputBox = ({ postId, accessToken, onNewComment }) => {
    const [newCommentContent, setNewCommentContent] = useState('');
    const [isContentEmpty, setIsContentEmpty] = useState(true);
    const [charCount, setCharCount] = useState(0);  // Track character count
    const [isWarning, setIsWarning] = useState(false);  // Show warning if limit is exceeded

    const CHAR_LIMIT = 7000;

    // Sanitize links when the component is mounted
    useEffect(() => {
        sanitizeLink();
    }, []);

    const handleCreateComment = () => {
        if (isContentEmpty) return;

        commentsApiHelpers.createComment(postId, newCommentContent, accessToken)
            .then(createdComment => {
                const user = {
                    authorName: `${window.localStorage.getItem('firstName')} ${window.localStorage.getItem('lastName')}`.trim(),
                    authorAvatarColor: window.localStorage.getItem('avatarColor') || '#3f51b5',
                    authorInitials: `${window.localStorage.getItem('firstName').charAt(0).toUpperCase()}${window.localStorage.getItem('lastName').charAt(0).toUpperCase()}`,
                    authorUserType: window.localStorage.getItem('userType') === "true", // Ensure authorUserType is set
                };

                const newCommentWithAuthorInfo = {
                    ...createdComment,
                    ...user
                };

                onNewComment(newCommentWithAuthorInfo);
            })
            .catch(error => console.error('Error creating comment:', error));

        setNewCommentContent(''); // Clear the input field
        setIsContentEmpty(true); // Reset the button state
        setCharCount(0); // Reset the character count
    };

    const handleContentChange = (content) => {
        const plainText = content.replace(/<\/?[^>]+(>|$)/g, ""); // Strip HTML tags to count only plain text characters
        const currentCharCount = plainText.length;

        // Prevent typing if character limit is reached
        if (currentCharCount <= CHAR_LIMIT) {
            setNewCommentContent(content);
            setCharCount(currentCharCount);
            setIsWarning(false); // No warning if within the limit
        } else {
            setIsWarning(true);  // Show warning if content exceeds the limit
        }

        setIsContentEmpty(currentCharCount === 0 || content === '<p><br></p>');
    }

    return (
        <Box sx={{ marginTop: 2 }}>
            <StyledCommentReactQuill>
                <ReactQuill
                    value={newCommentContent}
                    onChange={handleContentChange}
                    theme="snow"
                    style={{ height: '100%' }} // Make sure the editor takes up the full height of its container
                />
            </StyledCommentReactQuill>

            {/* Character Counter and Warning Message */}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: { xs: '7rem', sm: '3.5rem' } }}>
                {isWarning && (
                    <Typography variant="caption" color="error">
                        You have exceeded the character limit of {CHAR_LIMIT}.
                    </Typography>
                )}
                <Typography variant="body2" color={isWarning ? 'error' : 'textSecondary'}>
                    {charCount}/{CHAR_LIMIT} characters
                </Typography>
            </Box>

            <StyledCommentButtonContainer sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <StyledCommentButton
                    variant="contained"
                    color="primary"
                    onClick={handleCreateComment}
                    disabled={isContentEmpty || isWarning}  // Disable if content is empty or exceeds limit
                >
                    Comment
                </StyledCommentButton>
            </StyledCommentButtonContainer>
        </Box >
    );
};
