import React, { useState, useEffect } from 'react';
import { Box, Card, CircularProgress, Typography } from '@mui/material';
import { PageTitleBar } from '../../components/PageTitleBar';
import { HeaderSection } from '../../components/HeaderSection';
import { videosApiHelpers } from '../../helpers/videosApiHelpers';

export const MAPastEvents = () => {
    const [videos, setVideos] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // List of video details
    const videoList = [
        { fileName: 'module4session1ma.mp4', title: 'MA Specific: Module 4 - Special Education Part I', date: 'Wednesday, Jan 8, 12:30-2:30 PM EDT' },
        { fileName: 'academicscreeningma.mp4', title: 'MA Specific: Academic Screening', date: 'Wednesday, Dec 18, 12:30-2:30 PM EDT' },
        { fileName: 'module2session2ma.mp4', title: 'MA Specific: Module 2 - Data Stories Part II', date: 'Wednesday, Dec 4, 12:30-2:30 PM EDT' },
        { fileName: 'module2session1ma.mp4', title: 'MA Specific: Module 2 - Data Stories Part I', date: 'Thursday, Nov 21, 9:00-11:00 AM EDT' },
        { fileName: 'module1session3ma.mp4', title: 'MA Specific: Module 1 - Identification Part III', date: 'Thursday, Nov 14, 9:00-11:00 AM EDT' },
        { fileName: 'module1session2ma.mp4', title: 'MA Specific: Module 1 - Identification Part II', date: 'Tuesday, Oct 29, 12:30-2:30 PM EDT' },
        { fileName: 'module1session1part1ma.mp4', title: 'MA Specific: Module 1 - Identification Part I, Beginning', date: 'Tuesday, Oct 15, 12:30-2:30 PM EDT' },
        { fileName: 'module1session1ma.mp4', title: 'MA Specific: Module 1 - Identification Part I', date: 'Tuesday, Oct 15, 12:30-2:30 PM EDT' },
        // Add more videos as needed
    ];

    useEffect(() => {
        const fetchVideos = async () => {
            setLoading(true);
            setError(null);
            try {
                const videoData = await Promise.all(
                    videoList.map(async ({ fileName, title, date }) => {
                        const url = await videosApiHelpers.fetchSignedUrl(fileName);
                        return { fileName, title, date, url };
                    })
                );
                setVideos(videoData);
            } catch (err) {
                console.error('Error fetching signed URLs:', err);
                setError('Failed to load the videos. Please try again later.');
            } finally {
                setLoading(false);
            }
        };

        fetchVideos();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: 2,
            }}
        >
            {/* Page Title Bar */}
            <PageTitleBar icon="⏮️" title="Past Events" />
            {/* Header Section */}
            <HeaderSection title="Past Events" />

            {/* Video Cards */}
            {loading ? (
                <CircularProgress />
            ) : error ? (
                <Typography variant="body1" color="error">
                    {error}
                </Typography>
            ) : videos.length > 0 ? (
                videos.map(({ title, date, url }, index) => (
                    <Card
                        key={index}
                        sx={{
                            padding: 4,
                            marginTop: '2rem',
                            maxWidth: '800px',
                            width: '100%',
                            textAlign: 'center',
                            boxShadow: 3,
                            borderRadius: 2,
                            backgroundColor: '#f9f9f9',
                        }}
                    >
                        {/* Video Title and Date */}
                        <Typography variant="h6" sx={{ marginBottom: 1 }}>
                            {title}
                        </Typography>
                        <Typography variant="subtitle1" color="textSecondary" sx={{ marginBottom: 3 }}>
                            {date}
                        </Typography>

                        {/* Video Content */}
                        <Box
                            component="video"
                            src={url}
                            controls
                            controlsList="nodownload" // Disable the download button
                            sx={{
                                width: '100%',
                                height: 'auto',
                                borderRadius: 2,
                                boxShadow: 2,
                            }}
                        />
                    </Card>
                ))
            ) : (
                <Typography variant="body1">
                    No past events yet. Check back after the first event.
                </Typography>
            )}
        </Box>
    );
};
