import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { PageTitleBar } from './PageTitleBar';
import { HeaderSection } from './HeaderSection';
import { CreatePostBox } from './CreatePostBox';
import { PinnedPostCard } from './PinnedPostCard';
import { PostCard } from './PostCard/PostCard';
import { postsApiHelpers } from '../helpers/postsApiHelpers';

export const ForumPage = ({ title = '', icon = null, groupId, path = '', defaultCanPost = true, handleNewPostCount = () => { } }) => {
    const [posts, setPosts] = useState([]);
    const [canPost, setCanPost] = useState(defaultCanPost);
    const accessToken = window.localStorage.getItem("iat") || '';  // Safely access token with fallback
    const userType = window.localStorage.getItem("userType") || 'guest';  // Safely access userType with fallback

    useEffect(() => {
        // Fetch posts based on the group ID
        if (groupId && accessToken) {
            postsApiHelpers.fetchPosts(groupId, accessToken)
                .then(postsData => {
                    setPosts(postsData || []); // Set posts safely, with an empty array fallback
                })
                .catch(error => console.error('Error fetching posts:', error));
        }

        // Dynamically control posting permissions based on userType and path
        if (userType === "true") {
            setCanPost(true);  // Allow posting for Inlier team members
        } else if (path === "/announcements") {
            setCanPost(false); // Disable posting for non-Inlier users in the Announcements page
        }
    }, [groupId, accessToken, userType, path]);

    // Listen for hash changes and scroll to the specific post
    useEffect(() => {
        const handleHashChange = () => {
            const postIdFromHash = window.location.hash ? window.location.hash.substring(6) : null; // Remove the "#post-" part
            if (postIdFromHash && posts?.length > 0) {
                const postElement = document.getElementById(`post-${postIdFromHash}`);
                if (postElement) {
                    postElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                }
            }
        };

        // Call the handler on component mount in case there's already a hash
        handleHashChange();

        // Add the hash change event listener
        window.addEventListener("hashchange", handleHashChange);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener("hashchange", handleHashChange);
        };
    }, [posts]);

    const handleCreatePost = (newPost) => {
        if (!newPost) return; // Prevent errors if newPost is undefined

        postsApiHelpers.createPost(newPost, accessToken)
            .then(createdPost => {
                if (createdPost) {
                    createdPost.authorName = newPost.authorName;
                    createdPost.authorInitials = newPost.authorInitials;
                    createdPost.authorAvatarColor = newPost.authorAvatarColor;
                    createdPost.authorUserType = userType === 'true';  // Set the userType from the current user
                    createdPost.authorId = newPost.authorId;

                    setPosts([createdPost, ...posts]);

                    // Scroll to the newly created post
                    setTimeout(() => {
                        const postElement = document.getElementById(`post-${createdPost.id}`);
                        if (postElement) {
                            postElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                        }
                    }, 100); // Delay added to ensure the post is rendered before scrolling

                    // Call handleNewPostCount to update the sidebar new post count
                    handleNewPostCount();
                }
            })
            .catch(error => console.error('Error creating post:', error));
    };

    const handleUpdatePost = (postId, updatedPost) => {
        if (!postId || !updatedPost) return; // Avoid undefined access
        setPosts(posts.map(post => (post.id === postId ? updatedPost : post)));
    };

    const handleDeletePost = (postId) => {
        if (!postId) return; // Avoid undefined access
        setPosts(posts.filter(post => post.id !== postId));
    };

    return (
        <Box>
            <PageTitleBar icon={icon} title={title} />
            <HeaderSection title={title} />
            <Box sx={{ padding: 4 }}>
                {/* Conditionally show CreatePostBox based on canPost state */}
                {canPost && (
                    <CreatePostBox onCreatePost={handleCreatePost} groupId={groupId} />
                )}
                <Box sx={{ marginBottom: 4 }}>
                    <PinnedPostCard />
                </Box>
                {posts?.length > 0 ? (
                    posts.map((post) => (
                        <div id={`post-${post?.id}`} key={post?.id}>
                            <PostCard
                                post={post}
                                onUpdatePost={handleUpdatePost}
                                onDeletePost={handleDeletePost}
                            />
                        </div>
                    ))
                ) : (
                    <Typography>No new posts available.</Typography>
                )}
            </Box>
        </Box>
    );
};
