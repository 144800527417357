import React from 'react';
import { Dialog, DialogContent, DialogActions, Button, TextField, Typography } from '@mui/material';

export const ForgotPasswordModal = ({
    open,
    handleClose,
    resetEmail,
    setResetEmail,
    handleForgotPassword,
    resetLoading
}) => {
    return (
        <Dialog open={open} onClose={handleClose} transitionDuration={300} PaperProps={{ sx: { borderRadius: 3 } }}>
            <DialogContent sx={{ padding: '2rem' }}>
                <Typography variant="h6" gutterBottom>Reset Password</Typography>
                <TextField
                    label="Enter your email"
                    type="email"
                    value={resetEmail}
                    onChange={(e) => setResetEmail(e.target.value)}
                    fullWidth
                    required
                    sx={{ marginBottom: '1rem' }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="secondary">
                    Cancel
                </Button>
                <Button onClick={handleForgotPassword} color="primary" disabled={resetLoading}>
                    {resetLoading ? 'Sending...' : 'Send Reset Email'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
