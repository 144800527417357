import React from 'react';
import { List, ListItem, ListItemText, Divider, IconButton, Typography, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import { groupNametoPath } from "../../helpers/groupNametoPath";
import { notificationsApiHelpers } from "../../helpers/notificationsApiHelpers";

export const NotificationList = ({ notifications = [], onClose }) => { // Default to empty array
    // Sort notifications by createdAt in descending order, only if notifications is an array
    const sortedNotifications = Array.isArray(notifications) ? notifications.slice().sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)) : [];

    const navigate = useNavigate(); // Get the navigate function

    const handleNavigation = async (groupPath, postId, notificationId) => {
        try {
            // Mark the notification as read
            await notificationsApiHelpers.markNotificationAsRead(notificationId);

            // Update the notification as read in the state (if applicable) - handled by parent if needed
            // Trigger the navigation
            if (groupPath && postId) {
                navigate(`${groupPath}#post-${postId}`);
            }

            // Close the notification dialog
            onClose && onClose();
        } catch (err) {
            console.error("Failed to mark notification as read", err);
        }
    };

    return (
        <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
            {/* Header Section with Close Button */}
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '16px',
                    borderBottom: '1px solid #ddd',
                }}
            >
                <Typography variant="h6" component="div">
                    Notifications
                </Typography>
                <IconButton edge="end" onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </Box>

            {/* Conditional Rendering for Notifications List */}
            {sortedNotifications.length === 0 ? (
                <Box sx={{ padding: '16px', textAlign: 'center' }}>
                    <Typography variant="body1" color="textSecondary">
                        You have no notifications.
                    </Typography>
                </Box>
            ) : (
                <List>
                    {sortedNotifications.map((notification) => {
                        // Safely extract content and other values, falling back to empty strings
                        const contentWithoutPrefix = (notification?.content || "").replace(/New comment on your post: /, '');
                        const groupPath = groupNametoPath[notification?.groupName] || "/forum"; // Default to /forum if no match

                        return (
                            <React.Fragment key={notification?.id || Math.random()}>
                                <ListItem
                                    sx={{
                                        bgcolor: notification?.read ? 'transparent' : 'rgba(0, 0, 255, 0.1)', // Light blue background for unread notifications
                                    }}
                                >
                                    <ListItemText
                                        primary={
                                            <div style={{ display: 'block', textAlign: 'left' }}>
                                                {/* Trigger navigation and mark as read */}
                                                New comment on your
                                                <span
                                                    onClick={() => handleNavigation(groupPath, notification?.parentPostId, notification?.id)} // Handle click to mark as read and navigate
                                                    style={{
                                                        textDecoration: 'none',
                                                        color: 'blue',
                                                        paddingLeft: "0px",
                                                        display: "inline",
                                                        cursor: 'pointer',
                                                        fontWeight: notification?.read ? 'normal' : 'bold', // Bold for unread notifications
                                                    }}
                                                >
                                                    {" post:"}
                                                </span>
                                                <div style={{ paddingTop: "0.5rem", paddingBottom: "0.5rem" }}>
                                                    {contentWithoutPrefix.replace(/<[^>]+>/g, '')}
                                                </div>
                                            </div>
                                        }
                                        secondary={
                                            <Typography variant="body2" color="textSecondary" sx={{ fontSize: "11px" }}>
                                                {new Date(notification?.createdAt || Date.now()).toLocaleString()}
                                            </Typography>
                                        }
                                    />
                                </ListItem>
                                <Divider />
                            </React.Fragment>
                        );
                    })}
                </List>
            )}
        </Box>
    );
};
