import { BoldText } from "../styles/useStyles";

export const getPinnedPostContent = (pathname) => {
    switch (pathname) {
        case "/slife-policy":
            return {
                title: "SLIFE and Newcomer Policy",
                content: (
                    <>
                        <BoldText>
                            The SLIFE and Newcomer Policy group is a space to alert others to
                            old and new SLIFE and Newcomer policy on a federal and state level
                            and for us to discuss these policies. In this group you can:
                        </BoldText>
                        <ul>
                            <li>
                                Share direct links to policies that currently exist or
                                forthcoming policies
                            </li>
                            <li>
                                Share direct links to articles about past and future policies
                            </li>
                            <li>Discuss past and future policies</li>
                            <li>Ask questions about why certain policies are in place</li>
                            <li>
                                Brainstorm what policies should be put into place in order to
                                best support Newcomer and SLIFE learners
                            </li>
                        </ul>
                    </>
                )
            };
        case "/ask-inlier":
            return {
                title: "Ask Inlier",
                content: (
                    <>
                        <BoldText>
                            Welcome to the Ask Inlier group! This space is for you to ask
                            questions specifically to the Inlier Learning team.
                        </BoldText>
                        <p>In this group you can:</p>
                        <ul>
                            <li>
                                Ask a question or clarifying comment related to previous events
                            </li>
                            <li>
                                Ask for us to share research or other resources mentioned in
                                modules and webinars
                            </li>
                            <li>
                                Ask a question for which you are seeking an answer from the
                                Inlier team specifically
                            </li>
                        </ul>
                    </>
                )
            };
        case "/assessment":
            return {
                title: "Assessment",
                content: (
                    <>
                        <BoldText>
                            The assessment group is a space to discuss all things related to
                            assessment for SLIFE and Newcomer learners. In this group you can:
                        </BoldText>
                        <ul>
                            <li>
                                Share what assessments you use for identification and academic
                                needs
                            </li>
                            <li>Ask for feedback on your current assessment usage</li>
                            <li>
                                Discuss best practices and practical ways to assess and progress
                                monitor your students
                            </li>
                        </ul>
                    </>
                )
            };
        case "/identification":
            return {
                title: "Identification",
                content: (
                    <>
                        <BoldText>
                            The Identification group is a space to share your current SLIFE
                            and Newcomer identification practices and crowdsource everything
                            related to identifying English learners including tools and
                            logistics. In this group you can:
                        </BoldText>
                        <ul>
                            <li>
                                Share your current system for identifying English learners with a focus on Newcomer and SLIFE learners
                            </li>
                            <li>
                                Work together to come up with solutions to pressing identification challenges
                            </li>
                            <li>
                                Discuss steps you can take towards identifying needs of students in an equitable way
                            </li>
                        </ul>
                    </>
                )
            };
        case '/special-education':
            return {
                title: "Special Education",
                content: (
                    <>
                        <BoldText>
                            The Special Education group is a space for all special education related topics. Anything from MTSS to placement and IEP meetings. In this group you can:
                        </BoldText>
                        <ul>
                            <li>Ask questions related to special education topics including but not limited to: MTSS, bilingual assessment, progress monitoring, RTI, evaluations, referrals, placement, diagnosis/identification, legal considerations, how to have a team approach, IEP meetings</li>
                            <li>Share tips and systems that have worked well for you </li>
                            <li>Workshop solutions to complex challenges related to this topic</li>
                        </ul>
                    </>
                ),
            };
        case '/math':
            return {
                title: "Math",
                content: (
                    <>
                        <BoldText>
                            The math group is a space to seek support on all things related to math learning in English and first language of instruction. In this group you can:
                        </BoldText>
                        <ul>
                            <li>Discuss how you support your students math progress </li>
                            <li>Share how you determine placement for math especially for students who are older with foundational math skill needs</li>
                            <li>Discuss specific student questions around math (*remember never to post personally identifiable information about students - no names, ages, etc.)</li>
                        </ul>
                    </>
                ),
            };
        case '/reading':
            return {
                title: "Reading",
                content: (
                    <>
                        <BoldText>
                            The reading group is to discuss and seek support on all things related to reading in English and first language of instruction. In this group you can:
                        </BoldText>
                        <ul>
                            <li>Ask for support related to best reading assessment tools for multilingual learners and what considerations you should have when assessing in English vs. first language of instruction vs. home language</li>
                            <li>Discuss specific student questions around reading (*remember never to post personally identifiable information about students - no names, ages, etc.)</li>
                            <li>Share reading lessons and curriculum that have worked for your students and why</li>
                            <li>Seek support around reading instruction for learners who are older but have early literacy support needs</li>
                        </ul>
                    </>
                ),
            };

        case '/trauma-support':
            return {
                title: "Trauma Support",
                content: (
                    <>
                        <BoldText>The trauma support group is a space for educators seeking to understand and support students who have experienced trauma. In this group you can:
                        </BoldText>
                        <ul>
                            <li>Ask questions related to identifying behaviors related to trauma</li>
                            <li>Discuss how your teaching teams and school keep teachers updated on important but sensitive information</li>
                            <li>Share your strategies in creating a trauma informed classroom</li>
                        </ul>
                    </>
                ),
            };
        case '/behavioral-support':
            return {
                title: "Behavioral Support",
                content: (
                    <>
                        <BoldText>
                            The behavioral support group is a space for all questions related to behavior that you’re seeing in the school. In this group you can:
                        </BoldText>
                        <ul>
                            <li>Ask questions about student behavior that you are seeking support on (examples of this include but are not limited to: student putting their head down in class, constant lateness, missing school, etc)</li>
                            <li>Discuss best practices around supporting classroom and school behavior that is child centered and holistic</li>
                            <li>Share your methods of tracking behavior and implementing a plan</li>
                        </ul>
                    </>
                ),
            };
        case '/grading':
            return {
                title: "Grading",
                content: (
                    <>
                        <BoldText>
                            The grading group is a space to share and discuss best practices around grading Newcomer and SLIFE learners. In this group you can:
                        </BoldText>
                        <ul>
                            <li>Share your grading system for Newcomer and SLIFE learners and ask for feedback</li>
                            <li>Discuss more humanistic ways to grade students </li>
                            <li>Create a plan to move grading practices to align with a growth mindset approach </li>
                            <li>Ask for support around how you grade students who enter your school as older students in need of foundational literacy and/or math support</li>
                        </ul>
                    </>
                ),
            };
        case '/wins':
            return {
                title: "Wins",
                content: (
                    <>
                        <BoldText>
                            This is a space to celebrate you as an educator and your school and students. We want to applaud your growth and celebrate your wins. Share with us things you implemented that worked and students who are showing growth.This is also a space we want to hear about how you’re taking care of yourself. Took a vacation to make sure you’re in the right mindset to support your students? We want to hear about it.
                        </BoldText>
                        <ul>
                            <li>In this group we are bucket fillers by:</li>
                            <li>Commenting on other people’s wins and celebrating with them</li>
                            <li>Asking to learn more about someone’s success</li>
                        </ul>
                    </>
                ),
            };

        case '/challenges':
            return {
                title: "Challenges",
                content: (
                    <>
                        <BoldText>
                            The space is to share the challenges and hard moments of educating that you’re experiencing and the challenges you’re seeing students face. In this group we are bucket fillers by:
                        </BoldText>
                        <ul>
                            <li>Showing genuine support for someone’s challenge through words of understanding and encouragement.</li>
                            <li>Helping solve problems when someone asks. If you are posting a challenge that you’re having and want genuine problem solving, please indicate that in your post. (For example: “I’m really struggling right now with morale in my classroom and I’m looking for some problem solving ideas of how I can make things more positive”)</li>
                        </ul>
                    </>
                ),
            };
        case '/state/massachusetts':
            return {
                title: "Massachusetts",
                content: (
                    <>
                        <BoldText>
                            Welcome to the Massachusetts group! We are so glad you are here. This group is a closed group for everyone who works in Massachusetts. Feel free to share information and ask questions that relate to Massachusetts educators only. Examples are when you have a question about the MA SLIFE guidance or MA specific Zoom sessions.
                        </BoldText>
                    </>
                ),
            };
        case '/state/maryland':
            return {
                title: "Maryland",
                content: (
                    <>
                        <BoldText>
                            Welcome to the Maryland group! We are so glad you are here.
                            This group is a closed group for everyone who works in Maryland.
                            Feel free to share information and ask questions that relate to Maryland educators only.
                            Examples are when you have a question about Maryland specific guidance that don’t pertain to other states.
                        </BoldText>
                    </>
                ),
            };
        case '/state/idaho':
            return {
                title: "Idaho",
                content: (
                    <>
                        <BoldText>
                            Welcome to the Idaho group! We are so glad you are here. This group is a closed group for everyone who works in Idaho. Feel free to share information and ask questions that relate to Idaho educators only. Examples are when you have a question about Idaho specific guidance that don’t pertain to other states.
                        </BoldText>
                    </>
                ),
            };
        case '/state/connecticut':
            return {
                title: "Connecticut",
                content: (
                    <>
                        <BoldText>
                            Welcome to the Connecticut group! We are so glad you are here. This group is a closed group for everyone who works in Connecticut. Feel free to share information and ask questions that relate to Connecticut educators only. Examples are when you have a question about Connecticut specific guidance that don’t pertain to other states.
                        </BoldText>
                    </>
                ),
            };
        case '/state/newhampshire':
            return {
                title: "New Hampshire",
                content: (
                    <>
                        <BoldText>
                            Welcome to the New Hampshire group! We are so glad you are here. This group is a closed group for everyone who works in New Hampshire. Feel free to share information and ask questions that relate to New Hampshire educators only. Examples are when you have a question about Idaho specific guidance that don’t pertain to other states.
                        </BoldText>
                    </>
                ),
            };
        case '/announcements':
            return {
                title: "Announcements",
                content: (
                    <>
                        <BoldText>
                            📝 Welcome to the Announcements Group!
                        </BoldText>
                        <p> Hello everyone! 👋</p>
                        <p>This is where you’ll find all platform-wide updates and news from the Inlier team, including new features, upcoming events, and other important announcements.</p>
                        <p> Please note:</p>
                        <p> While only the Inlier team can post here, you’re welcome to comment on any announcements and stay engaged.</p>
                        <p>Thanks for being part of our community!</p>
                        <p>— The Inlier Team</p>
                    </>
                ),
            };

        // Add cases for other paths
        default:
            return null;
    }
};

// case '/':
// return {
//     title: "",
//     content: (
//         <>
//             <BoldText>

//             </BoldText>
//             <ul>
//                 <li></li>
//                 <li></li>
//                 <li></li>
//             </ul>
//         </>
//     ),
// };
