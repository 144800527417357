import React from 'react';
import { Box, Typography, Avatar } from '@mui/material';
import { formatTimestamp } from '../../helpers/formatTimestamp';
import { InlierTeamBadge } from '../InlierTeamBadge'; // Import the badge component

export const PostHeader = ({ authorName, authorInitials, authorAvatarColor, authorUserType, createdAt, tags }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: 2,
                flexDirection: {
                    xs: 'row',  // Stack on small screens
                    sm: 'row'      // Row on larger screens
                },
                gap: {
                    xs: 2,        // More space between items on mobile
                    sm: 0         // No gap on larger screens
                }
            }}
        >
            {/* Left side with Avatar and Author details */}
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Avatar sx={{ backgroundColor: authorAvatarColor || '#3f51b5' }}>
                    {authorInitials || 'U'}
                </Avatar>
                <Box sx={{ marginLeft: 2 }}>
                    <Typography variant="subtitle2" color="textPrimary" sx={{ display: 'flex', alignItems: 'center' }}>
                        {authorName || 'Unknown Author'}
                        {authorUserType === true && <InlierTeamBadge />}
                    </Typography>
                    <Typography variant="caption" color="textSecondary">
                        {formatTimestamp(createdAt)}
                    </Typography>
                </Box>
            </Box>

            {/* Right side with Tags */}
            <Box
                sx={{
                    display: 'flex',
                    gap: 1,
                    flexWrap: 'wrap',  // Allow wrapping to prevent overflow
                    justifyContent: {
                        xs: 'flex-start',  // Align left on mobile
                        sm: 'flex-end'     // Align right on larger screens
                    },
                    width: {
                        xs: '100%',        // Full width on mobile
                        sm: 'auto'         // Auto width on larger screens
                    }
                }}
            >
                {tags && tags.length > 0 && tags.map((tag, index) => (
                    <Typography
                        key={index}
                        variant="caption"
                        color="textSecondary"
                        sx={{
                            padding: '2px 6px',
                            borderRadius: '12px',
                            backgroundColor: '#e0e0e0',
                            fontSize: '0.75rem',
                            fontWeight: 'bold'
                        }}
                    >
                        {tag}
                    </Typography>
                ))}
            </Box>
        </Box>
    );
};
