import React, { useState, useEffect } from 'react';
import { Drawer, Divider, Dialog, DialogContent, Badge } from '@mui/material';
import { SidebarContainer, MobileMenuButton, TopNavBar, TopNavBarTitle, TopBar, drawerWidth } from "../../styles/useStyles";
import MenuIcon from '@mui/icons-material/Menu';
import { SidebarContent } from './SidebarContent';
import { UserAvatar } from './UserAvatar';
import logoBlack from '../../styles/images/logoBlack.png';
import { styled } from '@mui/material/styles';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { NotificationList } from './NotificationList';

const FixedImageContainer = styled('div')(({ theme }) => ({
    position: 'fixed',
    top: 56, // Adjust to match the TopBar height
    width: `${drawerWidth}px`, // Account for the sidebar width
    backgroundColor: 'white', // Match the background color
    zIndex: 10,
    boxSizing: 'border-box',
}));

const ScrollableListContainer = styled('div')({
    marginTop: 70, // Push the list down to make space for the fixed image
    overflowY: 'auto',
});

export const Sidebar = ({ userType = 'guest', newPostsCount = 0, notifications = [] }) => {
    const [mobileOpen, setMobileOpen] = useState(false);
    const [notificationsOpen, setNotificationsOpen] = useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    // Ensure mobile drawer closes on resize if window width is less than 768px
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 768) {
                setMobileOpen(false);
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleNotificationsOpen = () => {
        setNotificationsOpen(true);
    };

    const handleNotificationsClose = () => {
        setNotificationsOpen(false);
    };

    // Use optional chaining to safely access properties and prevent undefined errors
    const unreadNotificationCount = notifications?.filter?.(notification => !notification?.read)?.length || 0; // Default to 0 if undefined

    return (
        <div>
            <TopBar>
                <UserAvatar />
                <Badge
                    badgeContent={unreadNotificationCount} // Only count unread notifications
                    color="primary"
                    overlap="circular"
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    onClick={handleNotificationsOpen}
                    style={{ cursor: 'pointer' }}
                >
                    <NotificationsIcon style={{ color: 'black' }} />
                </Badge>
            </TopBar>

            {/* Notification Dialog */}
            <Dialog open={notificationsOpen} onClose={handleNotificationsClose}>
                <DialogContent>
                    <NotificationList notifications={notifications} onClose={handleNotificationsClose} />
                </DialogContent>
            </Dialog>

            <TopNavBar>
                <MobileMenuButton onClick={handleDrawerToggle}>
                    <MenuIcon style={{ color: 'black' }} />
                </MobileMenuButton>
                <TopNavBarTitle>
                    <img src={logoBlack} alt="Logo" style={{ width: '100px', objectFit: 'contain' }} />
                </TopNavBarTitle>
                <UserAvatar />
                <Badge
                    badgeContent={unreadNotificationCount} // Only count unread notifications
                    color="primary"
                    overlap="circular"
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    onClick={handleNotificationsOpen}
                    style={{ cursor: 'pointer' }}
                >
                    <NotificationsIcon style={{ color: 'black' }} />
                </Badge>
                {/* Notification Dialog */}
                <Dialog open={notificationsOpen} onClose={handleNotificationsClose}>
                    <DialogContent>
                        <NotificationList notifications={notifications} onClose={handleNotificationsClose} />
                    </DialogContent>
                </Dialog>
            </TopNavBar>

            <Drawer
                variant="permanent"
                sx={{
                    display: { xs: 'none', md: 'block' },
                    width: drawerWidth,
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box', top: 56, height: 'calc(100% - 56px)' },
                }}
                open
            >
                <SidebarContainer>
                    <FixedImageContainer>
                        <img src={logoBlack} alt="Logo" style={{ width: '100px', objectFit: 'contain' }} />
                        <Divider />
                    </FixedImageContainer>
                    <ScrollableListContainer>
                        <SidebarContent userType={userType} newPostsCount={newPostsCount} />
                    </ScrollableListContainer>
                </SidebarContainer>
            </Drawer>

            <Drawer
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{ keepMounted: true }}
                sx={{
                    display: { xs: 'block', md: 'none' },
                    [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box', paddingTop: '56px' },
                }}
            >
                <SidebarContainer>
                    <SidebarContent userType={userType} newPostsCount={newPostsCount} />
                </SidebarContainer>
            </Drawer>
        </div>
    );
};
