import { ForumPage } from '../../components/ForumPage';
import { groupUrltoId } from '../../helpers/groupUrltoId';

export const Grading = ({ handleNewPostCount }) => {

    const groupId = groupUrltoId['/grading'];

    return (
        <ForumPage title="Grading" icon="🟡" groupId={groupId} path="/grading" handleNewPostCount={handleNewPostCount} />
    )

};