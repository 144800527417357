import React, { useState } from 'react';
import { LoginContainer } from '../styles/useStyles';
import { LoginForm } from '../components/Login/LoginForm';
import { ForgotPasswordModal } from '../components/Login/ForgotPasswordModal';
import { LoadingSpinner } from '../components/LoadingSpinner';
import { toast } from 'react-toastify';
import { api } from "../helpers/axios";

export const Login = () => {
    const [loading, setLoading] = useState(null);
    const [forgotPasswordOpen, setForgotPasswordOpen] = useState(false); // Modal open state
    const [resetEmail, setResetEmail] = useState('');
    const [resetLoading, setResetLoading] = useState(false);

    const handleForgotPassword = async () => {
        if (!resetEmail) {
            toast.error('Please enter your account email.');
            return;
        }
        setResetLoading(true);
        api.post('/auth/passwordreset', { email: resetEmail })
            .then(() => {
                toast.success('If the email is registered, you’ll receive password reset instructions shortly. Please allow up to 15 minutes.');
                setForgotPasswordOpen(false);
                setResetEmail('');
            })
            .catch(err => {
                toast.success('If the email is registered, you’ll receive password reset instructions shortly. Please allow up to 15 minutes.');
                setForgotPasswordOpen(false);
                setResetEmail('');
            })
            .finally(() => setResetLoading(false));
    };

    return (
        <LoginContainer>
            <LoginForm loading={loading} setLoading={setLoading} setForgotPasswordOpen={setForgotPasswordOpen} />
            <LoadingSpinner loading={loading} />

            {/* Forgot Password Modal */}
            <ForgotPasswordModal
                open={forgotPasswordOpen}
                handleClose={() => setForgotPasswordOpen(false)}
                resetEmail={resetEmail}
                setResetEmail={setResetEmail}
                handleForgotPassword={handleForgotPassword}
                resetLoading={resetLoading}
            />
        </LoginContainer>
    );
};
