import React from 'react';
import { Typography, Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export const NotFound = () => {
    const navigate = useNavigate();

    return (
        <Box sx={{ textAlign: 'center', marginTop: '50px' }}>
            <Typography variant="h1" component="h1">404</Typography>
            <Typography variant="h6">Page Not Found</Typography>
            <Button variant="contained" color="primary" onClick={() => navigate('/')}>
                Go to Login
            </Button>
        </Box>
    );
};
