import React from 'react';
import { Box } from '@mui/material';
import { PinIcon, PinnedPostCard as StyledPinnedPostCard } from '../styles/useStyles';
import PushPinIcon from '@mui/icons-material/PushPin';
import { useLocation } from 'react-router-dom';
import { getPinnedPostContent } from '../helpers/pinnedPostContentProvider';

export const PinnedPostCard = () => {
    const location = useLocation();
    const pinnedPostContent = getPinnedPostContent(location.pathname);

    if (!pinnedPostContent) return null;

    return (
        <StyledPinnedPostCard>
            <PinIcon>
                <PushPinIcon />
            </PinIcon>
            <Box sx={{ marginTop: "2rem" }}>
                {pinnedPostContent.content}
            </Box>
        </StyledPinnedPostCard>
    );
};
