import { api } from './axios';
import { checkAndRefreshToken } from './checkAndRefreshToken';

export const commentsApiHelpers = {
    fetchComments: async (postId) => {
        const isTokenValid = await checkAndRefreshToken();
        const accessToken = window.localStorage.getItem("iat");

        if (!isTokenValid || !accessToken) {
            throw new Error('Unable to authenticate');
        }

        const response = await api.get(`/comments/post/${postId}`, {
            withCredentials: true,
            headers: {
                "Content-type": "application/json",
                authorization: `Bearer ${accessToken}`
            }
        });

        return response.data.message;
    },

    createComment: async (postId, content) => {
        const isTokenValid = await checkAndRefreshToken();
        const accessToken = window.localStorage.getItem("iat");

        if (!isTokenValid || !accessToken) {
            throw new Error('Unable to authenticate');
        }

        const response = await api.post('/comments', { postId, content }, {
            withCredentials: true,
            headers: {
                Accept: "application/json",
                authorization: `Bearer ${accessToken}`
            }
        });
        return response.data.message;
    },

    // New: Update Comment
    updateComment: async (commentId, updatedContent) => {
        const isTokenValid = await checkAndRefreshToken();
        const accessToken = window.localStorage.getItem("iat");

        if (!isTokenValid || !accessToken) {
            throw new Error('Unable to authenticate');
        }

        const response = await api.put(`/comments/${commentId}`, updatedContent, {
            withCredentials: true,
            headers: {
                "Content-type": "application/json",
                authorization: `Bearer ${accessToken}`
            }
        });
        return response.data.message;
    },

    // New: Delete Comment
    deleteComment: async (commentId) => {
        const isTokenValid = await checkAndRefreshToken();
        const accessToken = window.localStorage.getItem("iat");

        if (!isTokenValid || !accessToken) {
            throw new Error('Unable to authenticate');
        }

        const response = await api.delete(`/comments/${commentId}`, {
            withCredentials: true,
            headers: {
                authorization: `Bearer ${accessToken}`
            }
        });
        return response.data.message;
    }
};
