import { ForumPage } from '../../components/ForumPage';
import { groupUrltoId } from '../../helpers/groupUrltoId';

export const Assessment = ({ handleNewPostCount }) => {

    const groupId = groupUrltoId['/assessment'];

    return (
        <ForumPage title="Assessment" icon="🟡" groupId={groupId} path="/assessment" handleNewPostCount={handleNewPostCount} />
    )

};