import React, { useState } from 'react';
import { Box, Typography, Avatar, Divider, IconButton, Button, Tooltip } from '@mui/material';
import DOMPurify from 'dompurify';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { jwtDecode } from 'jwt-decode'; // Import jwt-decode
import ReactQuill from 'react-quill'; // Import ReactQuill
import 'react-quill/dist/quill.snow.css'; // Import styles for ReactQuill
import 'react-quill/dist/quill.core.css';
import { formatTimestamp } from '../../helpers/formatTimestamp';
import { InlierTeamBadge } from '../InlierTeamBadge'; // Import the badge component
import { commentsApiHelpers } from '../../helpers/commentsApiHelpers';
import { styled } from '@mui/system';

const StyledBox = styled(Box)(({ theme }) => ({
    '.ql-indent-1': {
        paddingLeft: '1em',
        listStyleType: 'lower-alpha',
    },
    '.ql-indent-2': {
        paddingLeft: '2em',
        listStyleType: 'lower-roman',
    },
    '.ql-indent-3': {
        paddingLeft: '3em',
        listStyleType: 'disc', // Disc bullets for .ql-indent-3
    },
    'ol': {
        listStyleType: 'decimal', // Ensure top-level <li> items are numbered
        paddingLeft: '1em',
    },
    'ol > li': {
        listStyleType: 'decimal', // Numbers for only top-level items
    },
}));

export const CommentList = ({ comments, onUpdateComment, onDeleteComment }) => {
    const [editingCommentId, setEditingCommentId] = useState(null);
    const [editedContent, setEditedContent] = useState('');
    const [charCount, setCharCount] = useState(0);  // Track character count
    const [isWarning, setIsWarning] = useState(false);  // Show warning if limit is exceeded

    const CHAR_LIMIT = 7000;

    // Decode JWT token to extract userId from the payload
    const accessToken = window.localStorage.getItem("iat");
    const decodedToken = jwtDecode(accessToken);
    const loggedInUserId = decodedToken.userId; // Get userId from the decoded token

    // Function to toggle edit mode for a comment
    const handleEditToggle = (commentId, currentContent) => {
        setEditingCommentId(commentId);
        setEditedContent(currentContent);
        setCharCount(currentContent.replace(/<\/?[^>]+(>|$)/g, "").length); // Set initial character count
        setIsWarning(false); // Reset warning when starting to edit
    };

    // Function to save edited comment
    const handleSaveEdit = (commentMetaData) => {
        const updatedCommentData = {
            content: editedContent,
            authorName: commentMetaData.authorName,
            authorAvatarColor: commentMetaData.authorAvatarColor,
            authorInitials: commentMetaData.authorInitials,
            authorUserType: commentMetaData.authorUserType,
            authorId: commentMetaData.authorId,
            createdAt: commentMetaData.createdAt,
            id: commentMetaData.id
        };

        commentsApiHelpers.updateComment(commentMetaData.id, updatedCommentData)
            .then((updatedComment) => {
                onUpdateComment(commentMetaData.id, updatedComment);
                setEditingCommentId(null); // Exit edit mode
            })
            .catch(error => console.error('Error updating comment:', error));
    };

    // Function to delete a comment
    const handleDelete = (commentId) => {
        commentsApiHelpers.deleteComment(commentId)
            .then(() => onDeleteComment(commentId))
            .catch(error => console.error('Error deleting comment:', error));
    };

    // Handle content changes and character count for editing
    const handleContentChange = (content) => {
        const plainText = content.replace(/<\/?[^>]+(>|$)/g, ""); // Strip HTML tags to count only plain text characters
        const currentCharCount = plainText.length;

        // Prevent typing if character limit is reached
        if (currentCharCount <= CHAR_LIMIT) {
            setEditedContent(content);
            setCharCount(currentCharCount);
            setIsWarning(false); // No warning if within the limit
        } else {
            setIsWarning(true);  // Show warning if content exceeds the limit
        }
    };

    return (
        <Box>
            {comments.map((comment) => {
                // Sanitize the HTML content of the comment
                const sanitizedContent = DOMPurify.sanitize(comment.content, {
                    ALLOWED_TAGS: ['a', 'b', 'i', 'em', 'strong', 'p', 'div', 'span', 'ol', 'ul', 'li'],
                    ALLOWED_ATTR: ['href', 'target', 'rel', 'class', 'id', 'name', 'style']
                });
                return (
                    <Box key={comment.id} sx={{ marginBottom: 2 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                            <Avatar sx={{ backgroundColor: comment.authorAvatarColor || '#3f51b5' }}>
                                {comment.authorInitials || 'U'}
                            </Avatar>
                            <Box sx={{ marginLeft: 2, flexGrow: 1 }}>
                                <Typography variant="subtitle2" color="textPrimary" sx={{ display: 'flex', alignItems: 'center' }}>
                                    {comment.authorName || 'Unknown Author'}
                                    {/* Show the badge if the comment author is part of the Inlier team */}
                                    {comment.authorUserType === true && <InlierTeamBadge />}
                                </Typography>
                                <Typography variant="caption" color="textSecondary">
                                    {formatTimestamp(comment.createdAt)}
                                </Typography>
                            </Box>

                            {/* Edit and Delete buttons only visible if logged-in user is the author */}
                            {comment.authorId === loggedInUserId && (
                                <>
                                    <Tooltip title="Edit Comment">
                                        <IconButton size="small" onClick={() => handleEditToggle(comment.id, comment.content)} sx={{ marginLeft: 'auto' }}>
                                            <EditIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Delete Comment">
                                        <IconButton size="small" onClick={() => handleDelete(comment.id)}>
                                            <DeleteIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                </>
                            )}
                        </Box>

                        {/* If editing, show ReactQuill editor with character count */}
                        {editingCommentId === comment.id ? (
                            <Box>
                                <ReactQuill
                                    value={editedContent}
                                    onChange={handleContentChange} // Update the edited content with ReactQuill's value
                                    theme="snow"
                                    style={{ marginBottom: '1rem' }}
                                />

                                {/* Character Counter and Warning Message */}
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '0.5rem' }}>
                                    {isWarning && (
                                        <Typography variant="caption" color="error">
                                            You have exceeded the character limit of {CHAR_LIMIT}.
                                        </Typography>
                                    )}
                                    <Typography variant="body2" color={isWarning ? 'error' : 'textSecondary'}>
                                        {charCount}/{CHAR_LIMIT} characters
                                    </Typography>
                                </Box>

                                <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 1 }}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        startIcon={<SaveIcon />}
                                        onClick={() => handleSaveEdit(comment)}
                                        disabled={isWarning}  // Disable save if content exceeds the limit
                                        sx={{ marginRight: 1 }}
                                    >
                                        Save
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        color="secondary"
                                        size="small"
                                        startIcon={<CancelIcon />}
                                        onClick={() => setEditingCommentId(null)}
                                    >
                                        Cancel
                                    </Button>
                                </Box>
                            </Box>
                        ) : (
                            <StyledBox
                                sx={{ color: '#555', marginBottom: 1 }}
                                dangerouslySetInnerHTML={{ __html: sanitizedContent }}
                            />
                        )}

                        <Divider sx={{ marginTop: 1 }} />
                    </Box>
                );
            })}
        </Box>
    );
};
