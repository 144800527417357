import React, { useState, useEffect, useCallback } from 'react';
import { jwtDecode } from 'jwt-decode'; // Import jwt-decode
import { PostHeader } from './PostHeader';
import { PostContent } from './PostContent';
import { PostActions } from './PostActions';
import { PostCommentSection } from './PostCommentSection';
import { commentsApiHelpers } from '../../helpers/commentsApiHelpers';
import { postsApiHelpers } from '../../helpers/postsApiHelpers';
import { StyledPostCard } from "../../styles/useStyles";

export const PostCard = ({ post, onUpdatePost, onDeletePost, openComments = false }) => {
    const [comments, setComments] = useState([]);
    const [showComments, setShowComments] = useState(openComments);
    const [loadingComments, setLoadingComments] = useState(false);
    const [commentCount, setCommentCount] = useState(post.commentCount);
    const [tags, setTags] = useState(post.tags || []);  // Add tags state
    const accessToken = window.localStorage.getItem("iat");

    // Decode JWT token to extract userId and other metadata
    const decodedToken = jwtDecode(accessToken);
    const loggedInUserId = decodedToken.userId;
    const authorName = decodedToken.name;
    const authorInitials = decodedToken.initials || 'U';
    const authorAvatarColor = '#3f51b5';
    const authorUserType = decodedToken.userType || false;

    // State for editing the post    
    const [isEditing, setIsEditing] = useState(false);
    const [editedContent, setEditedContent] = useState(post.content);
    const [editedTitle, setEditedTitle] = useState(post.title);

    // State for showing delete confirmation
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

    const handleShowComments = useCallback(() => {
        if (showComments) {
            setShowComments(false);
            setComments([]);
        } else {
            setLoadingComments(true);
            commentsApiHelpers.fetchComments(post.id, accessToken)
                .then(commentsData => {
                    setComments(commentsData);
                    setShowComments(true);
                    setLoadingComments(false);
                })
                .catch(error => {
                    console.error('Error fetching comments:', error);
                    setLoadingComments(false);
                });
        }
    }, [showComments, accessToken, post.id]);

    useEffect(() => {
        if (openComments) {
            handleShowComments();
        }
    }, [openComments, handleShowComments]);

    // Ensure comments get the full metadata on update
    const handleUpdateComment = (commentId, updatedComment) => {
        setComments(comments.map(comment =>
            comment.id === commentId ? { ...comment, ...updatedComment } : comment
        ));
    };

    const handleDeleteComment = (commentId) => {
        setComments(comments.filter(comment => comment.id !== commentId));
        setCommentCount(commentCount - 1);
    };

    // Handling new comments with all the required metadata
    const handleNewComment = (newComment) => {

        // Only overwrite author metadata if it's missing in the newComment
        const commentWithMetadata = {
            ...newComment,
            authorId: newComment.authorId || loggedInUserId,  // Use newComment's authorId if available, otherwise use loggedInUserId
            authorName: newComment.authorName || authorName,  // Use newComment's authorName if available, otherwise use loggedInUser's name
            authorInitials: newComment.authorInitials || authorInitials,  // Same for initials
            authorAvatarColor: newComment.authorAvatarColor || authorAvatarColor,  // Same for avatar color
            authorUserType: newComment.authorUserType || authorUserType  // Same for userType
        };

        // Add the comment to the list
        setComments([commentWithMetadata, ...comments]);
        setCommentCount(commentCount + 1); // Increment the comment count
    };


    const handleSaveEdit = () => {
        const updatedPostData = {
            title: editedTitle,
            content: editedContent,
            tags,  // Include tags in update
            authorName: post.authorName,
            authorAvatarColor: post.authorAvatarColor,
            authorInitials: post.authorInitials,
            authorUserType: post.authorUserType,
            authorId: post.authorId,
            createdAt: post.createdAt,
            id: post.id
        };

        postsApiHelpers.updatePost(post.id, updatedPostData)
            .then(() => {
                onUpdatePost(post.id, updatedPostData);
                setIsEditing(false);
            })
            .catch(error => console.error('Error updating post:', error));
    };

    const handleDelete = () => {
        postsApiHelpers.deletePost(post.id)
            .then(() => onDeletePost(post.id))
            .catch(error => console.error('Error deleting post:', error));
        setDeleteDialogOpen(false); // Close dialog after deletion
    };

    return (
        <StyledPostCard>
            <PostHeader
                authorName={post.authorName}
                authorInitials={post.authorInitials}
                authorAvatarColor={post.authorAvatarColor}
                authorUserType={post.authorUserType}
                createdAt={post.createdAt}
                tags={tags}  // Pass tags to PostHeader
            />

            <PostContent
                isEditing={isEditing}
                editedTitle={editedTitle}
                setEditedTitle={setEditedTitle}
                editedContent={editedContent}
                setEditedContent={setEditedContent}
                postContent={post.content}
                postTitle={post.title}
                tags={tags}  // Pass tags
                setTags={setTags}  // Pass setTags function
                handleSaveEdit={handleSaveEdit}
                handleEditToggle={() => setIsEditing(!isEditing)}
            />

            <PostActions
                showComments={showComments}
                commentCount={commentCount}
                handleShowComments={handleShowComments}
                handleEditToggle={() => setIsEditing(!isEditing)}
                handleDelete={handleDelete}
                deleteDialogOpen={deleteDialogOpen}
                setDeleteDialogOpen={setDeleteDialogOpen}
                canEdit={post.authorId === loggedInUserId}
            />
            {showComments && (
                <PostCommentSection
                    postId={post.id}
                    comments={comments}
                    setComments={setComments}
                    loadingComments={loadingComments}
                    accessToken={accessToken}
                    handleUpdateComment={handleUpdateComment}
                    handleDeleteComment={handleDeleteComment}
                    commentCount={commentCount}
                    setCommentCount={setCommentCount}
                    onNewComment={handleNewComment} // Passing the new comment handler
                />
            )}
        </StyledPostCard>
    );
};
