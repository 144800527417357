import React, { useState, useEffect } from 'react';
import { Box, Modal, Typography, Button, TextField, Autocomplete, Chip } from '@mui/material';
import { jwtDecode } from 'jwt-decode';
import { StyledModalBox } from '../styles/useStyles';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.core.css';
import { sanitizeLink } from '../helpers/sanitizeLink';

export const CreatePostModal = ({ open, handleClose, onCreatePost, groupId }) => {
    const [newPostTitle, setNewPostTitle] = useState('');
    const [newPostContent, setNewPostContent] = useState('');
    const [isContentEmpty, setIsContentEmpty] = useState(true);
    const [isTitleEmpty, setIsTitleEmpty] = useState(true);
    const [titleTouched, setTitleTouched] = useState(false);
    const [tags, setTags] = useState([]); // State for managing tags

    const [charCount, setCharCount] = useState(0); // Track character count
    const [isWarning, setIsWarning] = useState(false); // Show warning if limit is exceeded
    const CHAR_LIMIT = 7000; // Set the character limit

    const availableTags = ['SLIFE and Newcomer Policy', 'Assessment', 'Identification', 'Special Education', 'Math', 'Reading', 'Trauma Support', 'Behavioral Support', 'Grading'];

    const avatarColor = window.localStorage.getItem('avatarColor');
    const firstName = window.localStorage.getItem('firstName');
    const lastName = window.localStorage.getItem('lastName');

    const accessToken = window.localStorage.getItem("iat");

    // Decode JWT token to extract userId from the payload
    const decodedToken = jwtDecode(accessToken);
    const loggedInUserId = decodedToken.userId;

    const getInitials = (firstName, lastName) => {
        if (firstName && lastName) {
            return `${firstName.charAt(0).toUpperCase()}${lastName.charAt(0).toUpperCase()}`;
        }
        return 'U';
    };

    useEffect(() => {
        sanitizeLink();
    }, []);

    const handlePublish = () => {
        if (isTitleEmpty || isContentEmpty) {
            return; // Avoid empty posts
        }

        const newPost = {
            groupId,
            title: newPostTitle,
            content: newPostContent,
            authorName: `${firstName} ${lastName}`.trim(),
            authorAvatarColor: avatarColor || '#3f51b5',
            authorInitials: getInitials(firstName, lastName),
            authorId: loggedInUserId,
            tags // Include selected tags
        };

        onCreatePost(newPost);
        handleClose();
        setNewPostTitle('');
        setNewPostContent('');
        setIsTitleEmpty(true);
        setIsContentEmpty(true);
        setTags([]); // Reset tags after publishing
        setTitleTouched(false);
        setCharCount(0); // Reset the character count after publishing
        setIsWarning(false); // Reset warning state
    };

    const handleTitleChange = (e) => {
        const title = e.target.value;
        setNewPostTitle(title);
        setIsTitleEmpty(!title.trim());
        setTitleTouched(true);
    };

    const handleContentChange = (content) => {
        const plainText = content.replace(/<\/?[^>]+(>|$)/g, ""); // Strip HTML tags to count only plain text characters
        const currentCharCount = plainText.length;

        // Prevent typing if character limit is reached
        if (currentCharCount <= CHAR_LIMIT) {
            setNewPostContent(content);
            setCharCount(currentCharCount);
            setIsWarning(false); // No warning if within the limit
        } else {
            setIsWarning(true);  // Show warning if content exceeds the limit
        }

        setIsContentEmpty(!plainText.trim());
    };

    return (
        <Modal open={open} onClose={handleClose}>
            <StyledModalBox>
                <Typography variant="h6" gutterBottom>
                    Create a New Post
                </Typography>
                <TextField
                    label="Title (required)"
                    value={newPostTitle}
                    onChange={handleTitleChange}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    error={titleTouched && isTitleEmpty}
                    helperText={titleTouched && isTitleEmpty && "Title is required"}
                />
                <ReactQuill
                    value={newPostContent}
                    onChange={handleContentChange}
                    theme="snow"
                    style={{ height: '200px' }}
                />

                {/* Character Counter and Warning Message */}
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: { xs: "4rem", sm: '2rem' } }}>
                    {isWarning && (
                        <Typography variant="caption" color="error">
                            You have exceeded the character limit of {CHAR_LIMIT}.
                        </Typography>
                    )}
                    <Typography variant="body2" color={isWarning ? 'error' : 'textSecondary'}>
                        {charCount}/{CHAR_LIMIT} characters
                    </Typography>
                </Box>

                {/* Tags Multi-select with Autocomplete */}
                <Autocomplete
                    multiple
                    id="tags-filled"
                    options={availableTags}
                    value={tags}
                    onChange={(event, newValue) => {
                        if (newValue.length <= 2) {
                            setTags(newValue); // Limit to 2 tags
                        }
                    }}
                    renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                            <Chip
                                variant="outlined"
                                label={option}
                                {...getTagProps({ index })}
                            />
                        ))
                    }
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            label="Tags (Max 2)"
                            placeholder="Select up to 2 tags"
                            fullWidth
                        />
                    )}
                    sx={{
                        marginTop: {
                            xs: "1rem",  // Larger margin for mobile (xs breakpoint)
                            sm: "0.5rem",  // Smaller margin for tablet and above
                        },
                    }}
                />

                <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: "2rem" }}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handlePublish}
                        sx={{ width: '150px' }}
                        disabled={isTitleEmpty || isContentEmpty || isWarning}  // Disable if content is empty or too long
                    >
                        Publish
                    </Button>
                </Box>
            </StyledModalBox>
        </Modal>
    );
};
