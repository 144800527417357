import { ForumPage } from '../../components/ForumPage';
import { groupUrltoId } from '../../helpers/groupUrltoId';

export const Reading = ({ handleNewPostCount }) => {

    const groupId = groupUrltoId['/reading'];

    return (
        <ForumPage title="Reading" icon="🟡" groupId={groupId} path="/reading" handleNewPostCount={handleNewPostCount} />
    )

};