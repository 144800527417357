import { ForumPage } from '../../components/ForumPage';
import { groupUrltoId } from '../../helpers/groupUrltoId';

export const Maryland = ({ handleNewPostCount }) => {

    const groupId = groupUrltoId['/state/maryland'];

    return (
        <ForumPage title="Maryland" icon="📍" groupId={groupId} path="/state/maryland" handleNewPostCount={handleNewPostCount} />
    )

};