import React, { useState, useEffect } from 'react';
import { TextField, Button, Box, Typography, Card, IconButton, InputAdornment, Divider } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';  // Import the icons
import { api } from '../helpers/axios';
import { PageTitleBar } from '../components/PageTitleBar';

export const Settings = () => {
    const [formState, setFormState] = useState({
        firstName: '',
        lastName: '',
        email: '',
        state: '',
        district: '',
        existingPassword: '',
        newPassword: '',
        confirmNewPassword: '',
    });

    const [showPassword, setShowPassword] = useState({
        existing: false,
        new: false,
        confirm: false,
    });

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    // Password validation regex
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])[a-zA-Z\d!@#$%^&*]{8,}$/;

    // Fetch profile data from the API on component mount
    useEffect(() => {
        const fetchUserProfile = async () => {
            try {
                const token = window.localStorage.getItem('iat');  // Get token from localStorage
                const response = await api.get('/users/profile', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                if (response.status === 200) {
                    const { firstName, lastName, email, state, district } = response.data.message;
                    setFormState({
                        firstName,
                        lastName,
                        email,
                        state,
                        district,
                        existingPassword: '',
                        newPassword: '',
                        confirmNewPassword: '',
                    });
                }
            } catch (error) {
                setError('Failed to fetch profile data.');
            }
        };

        fetchUserProfile();
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormState((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');
        setSuccessMessage('');

        const { existingPassword, newPassword, confirmNewPassword } = formState;

        // Password validation
        if (!existingPassword) {
            setError('Please enter your existing password to update your password.');
            setLoading(false);
            return;
        }

        if (newPassword && (newPassword !== confirmNewPassword)) {
            setError('New password and confirmation do not match.');
            setLoading(false);
            return;
        }

        if (newPassword && !passwordRegex.test(newPassword)) {
            setError('New password must be at least 8 characters long, include one uppercase letter, one lowercase letter, and one special character.');
            setLoading(false);
            return;
        }

        const updateData = {
            existingPassword,
            ...(newPassword && { newPassword }),  // Add newPassword if it's entered
        };

        try {
            const token = window.localStorage.getItem('iat');  // Get token from localStorage
            const response = await api.put('/users/profile', updateData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.status === 200) {
                setSuccessMessage('Password updated successfully!');

                // Clear password fields after successful update
                setFormState((prevState) => ({
                    ...prevState,
                    existingPassword: '',
                    newPassword: '',
                    confirmNewPassword: '',
                }));
            }
        } catch (err) {
            setError('There was an issue updating your password.');
        } finally {
            setLoading(false);
        }
    };

    const togglePasswordVisibility = (field) => {
        setShowPassword((prevState) => ({
            ...prevState,
            [field]: !prevState[field],
        }));
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <PageTitleBar icon="⚙️" title="Settings" />
            <Card sx={{ padding: 4, maxWidth: "800px", marginTop: "5rem" }}>
                <Box sx={{ maxWidth: '400px' }}>
                    <Typography variant="h5" gutterBottom>Profile Information</Typography>

                    {error && <Typography color="error">{error}</Typography>}
                    {successMessage && <Typography color="primary">{successMessage}</Typography>}

                    {/* Display static profile information */}
                    <Box sx={{ marginBottom: 2 }}>
                        <Typography variant="subtitle2">First Name:</Typography>
                        <Typography>{formState.firstName}</Typography>
                    </Box>
                    <Box sx={{ marginBottom: 2 }}>
                        <Typography variant="subtitle2">Last Name:</Typography>
                        <Typography>{formState.lastName}</Typography>
                    </Box>
                    <Box sx={{ marginBottom: 2 }}>
                        <Typography variant="subtitle2">Email:</Typography>
                        <Typography>{formState.email}</Typography>
                    </Box>
                    <Box sx={{ marginBottom: 2 }}>
                        <Typography variant="subtitle2">State:</Typography>
                        <Typography>{formState.state}</Typography>
                    </Box>
                    <Box sx={{ marginBottom: 2 }}>
                        <Typography variant="subtitle2">District:</Typography>
                        <Typography>{formState.district}</Typography>
                    </Box>

                    <Divider sx={{ my: 3 }} />

                    {/* Password update section */}
                    <Typography variant="h5" gutterBottom>Update Password</Typography>

                    <TextField
                        label="Existing Password"
                        type={showPassword.existing ? "text" : "password"}
                        name="existingPassword"
                        value={formState.existingPassword}
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                        required
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() => togglePasswordVisibility('existing')}
                                        edge="end"
                                    >
                                        {showPassword.existing ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />

                    <TextField
                        label="New Password"
                        type={showPassword.new ? "text" : "password"}
                        name="newPassword"
                        value={formState.newPassword}
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() => togglePasswordVisibility('new')}
                                        edge="end"
                                    >
                                        {showPassword.new ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />

                    <TextField
                        label="Confirm New Password"
                        type={showPassword.confirm ? "text" : "password"}
                        name="confirmNewPassword"
                        value={formState.confirmNewPassword}
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() => togglePasswordVisibility('confirm')}
                                        edge="end"
                                    >
                                        {showPassword.confirm ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />

                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                        disabled={loading}
                        onClick={handleSubmit}
                    >
                        {loading ? 'Saving...' : 'Update Password'}
                    </Button>
                </Box>
            </Card>
        </Box>
    );
};
