import { ForumPage } from '../../components/ForumPage';
import { groupUrltoId } from '../../helpers/groupUrltoId';

export const Wins = ({ handleNewPostCount }) => {

    const groupId = groupUrltoId['/wins'];

    return (
        <ForumPage title="Wins" icon="🟡" groupId={groupId} path="/wins" handleNewPostCount={handleNewPostCount} />
    )

};