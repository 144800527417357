import React from 'react';
import { Typography } from '@mui/material';
import { StyledHeaderSection } from '../styles/useStyles';

export const HeaderSection = ({ title, subtitle }) => {
    return (
        <StyledHeaderSection>
            <Typography variant="h4" sx={{ fontWeight: 'bold', borderBottom: '6px dotted #562779' }}>
                {title}
            </Typography>
            {subtitle && (
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                    {subtitle}
                </Typography>
            )}
        </StyledHeaderSection>
    );
};
