import React, { useState } from 'react';
import { Box, TextField, Button, Autocomplete, Chip, Typography } from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.core.css';
import DOMPurify from 'dompurify';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { styled } from '@mui/system';

const StyledBox = styled(Box)(({ theme }) => ({
    '.ql-indent-1': {
        paddingLeft: '1em',
        listStyleType: 'lower-alpha',
    },
    '.ql-indent-2': {
        paddingLeft: '2em',
        listStyleType: 'lower-roman',
    },
    '.ql-indent-3': {
        paddingLeft: '3em',
        listStyleType: 'disc', // Disc bullets for .ql-indent-3
    },
    'ol': {
        listStyleType: 'decimal', // Ensure top-level <li> items are numbered
        paddingLeft: '1em',
    },
    'ol > li': {
        listStyleType: 'decimal', // Numbers for only top-level items
    },
}));

export const PostContent = ({
    isEditing,
    editedTitle,
    setEditedTitle,
    editedContent,
    setEditedContent,
    postContent,
    postTitle,
    tags,
    setTags,
    handleSaveEdit,
    handleEditToggle
}) => {
    const availableTags = ['SLIFE and Newcomer Policy', 'Assessment', 'Identification', 'Special Education', 'Math', 'Reading', 'Trauma Support', 'Behavioral Support', 'Grading'];

    const sanitizedContent = DOMPurify.sanitize(postContent, {
        ALLOWED_TAGS: ['a', 'b', 'i', 'em', 'strong', 'p', 'div', 'span', 'ol', 'ul', 'li'],
        ALLOWED_ATTR: ['href', 'target', 'rel', 'class', 'id', 'name', 'style']
    });

    // Character limits
    const TITLE_CHAR_LIMIT = 100;
    const CONTENT_CHAR_LIMIT = 7000;

    // State to track character counts and warnings
    const [titleCharCount, setTitleCharCount] = useState(editedTitle.length);
    const [contentCharCount, setContentCharCount] = useState(DOMPurify.sanitize(editedContent, { ALLOWED_TAGS: [] }).length);
    const [isTitleWarning, setIsTitleWarning] = useState(false);
    const [isContentWarning, setIsContentWarning] = useState(false);

    const handleTitleChange = (e) => {
        const newTitle = e.target.value;
        const newTitleCharCount = newTitle.length;

        setEditedTitle(newTitle);
        setTitleCharCount(newTitleCharCount);
        setIsTitleWarning(newTitleCharCount > TITLE_CHAR_LIMIT);
    };

    const handleContentChange = (content) => {
        const plainTextContent = DOMPurify.sanitize(content, { ALLOWED_TAGS: [] }); // Strip HTML to count only text
        const newContentCharCount = plainTextContent.length;

        setEditedContent(content);
        setContentCharCount(newContentCharCount);
        setIsContentWarning(newContentCharCount > CONTENT_CHAR_LIMIT);
    };

    const handleTagChange = (event, newValue) => {
        if (newValue.length <= 2) {
            setTags(newValue); // Only update tags if the selected tags are 2 or fewer
        }
    };

    return isEditing ? (
        <Box>
            <TextField
                label="Title"
                value={editedTitle}
                onChange={handleTitleChange}
                fullWidth
                margin="normal"
                helperText={`${titleCharCount}/${TITLE_CHAR_LIMIT} characters`}
                error={isTitleWarning}
            />
            {isTitleWarning && (
                <Typography variant="caption" color="error">
                    Title exceeds the {TITLE_CHAR_LIMIT} character limit.
                </Typography>
            )}

            <ReactQuill
                value={editedContent}
                onChange={handleContentChange}
                theme="snow"
                style={{ height: '200px', marginBottom: '2rem' }}
            />

            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '4rem' }}>
                {isContentWarning && (
                    <Typography variant="caption" color="error">
                        Content exceeds the {CONTENT_CHAR_LIMIT} character limit.
                    </Typography>
                )}
                <Typography variant="body2" color={isContentWarning ? 'error' : 'textSecondary'}>
                    {contentCharCount}/{CONTENT_CHAR_LIMIT} characters
                </Typography>
            </Box>

            {/* Autocomplete for tags */}
            <Autocomplete
                sx={{
                    marginTop: "2rem"
                }}
                multiple
                options={availableTags}
                value={tags}
                onChange={handleTagChange}
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                        <Chip key={option} label={option} {...getTagProps({ index })} />
                    ))
                }
                renderInput={(params) => (
                    <TextField {...params} variant="outlined" label="Tags (Max 2)" />
                )}
                limitTags={2} // Show only 2 tags in the UI
                disableCloseOnSelect
            />

            <Button
                variant="contained"
                color="primary"
                startIcon={<SaveIcon />}
                onClick={handleSaveEdit}
                sx={{ marginRight: 2, marginTop: "2rem" }}
                disabled={isTitleWarning || isContentWarning} // Disable if title or content exceed limits
            >
                Save
            </Button>
            <Button
                variant="outlined"
                startIcon={<CancelIcon />}
                onClick={handleEditToggle}
                sx={{ marginTop: "2rem" }}
            >
                Cancel
            </Button>
        </Box>
    ) : (
        <Box>
            <Typography variant="h6" gutterBottom>
                {postTitle}
            </Typography>
            <StyledBox sx={{ marginBottom: 2, color: '#555' }}>
                <Box dangerouslySetInnerHTML={{ __html: sanitizedContent }} />
            </StyledBox>

        </Box>
    );
};
