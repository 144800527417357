import React from 'react';
import { SpinnerDiamond } from "spinners-react";
import { Box } from '@mui/material';

export const LoadingSpinner = ({ loading }) => {
    return loading ? (
        <Box sx={{ width: "100%", textAlign: "center", marginTop: "1rem" }}>
            <SpinnerDiamond
                style={{ margin: "0 auto" }}
                color="#22223B"
                size={50}
                thickness={100}
                speed={100}
                secondaryColor="rgba(0, 0, 0, 0.44)"
            />
        </Box>
    ) : null;
};
