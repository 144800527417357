import React from 'react';
import { Typography } from '@mui/material';
import { StyledPageTitleBar } from '../styles/useStyles';

export const PageTitleBar = ({ icon, title }) => {
    return (
        <StyledPageTitleBar>
            <Typography variant="body" sx={{ display: 'flex', alignItems: 'center', color: '#FFD700' }}>
                {icon} &nbsp;
            </Typography>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                {title}
            </Typography>
        </StyledPageTitleBar>
    );
};
