import moduleone from '../styles/images/moduleone.png';

export const MAUpcomingEventsData = {
    featuredEvent: {
        title: "MA Specific: Module 4 - Special Education Part II",
        date: {
            dayAndTime: "Wednesday, Jan 29, 12:30-2:30 PM EDT",
            dayNumber: "29",
            month: "Jan"
        },
        description: `In Module 4 you will build upon modules 1-3 and cover the unique MTSS considerations for Newcomer and SLIFE learners. This module includes better understanding where Newcomer and SLIFE learners fit into the Special Education process, how the data from identification system builds a stronger foundation for determining evaluations or not, and what frameworks should be used when making these decisions.`,
        image: moduleone,
        zoomLink: `https://us02web.zoom.us/j/83073322781?pwd=7fn353ubGTimVK06niSVV68GQyzoS9.1`,
        zoomDetails: `Orly Klapholz (she/her) is inviting you to a scheduled Zoom meeting.
        Topic: COA Module MA
        Time: This is a recurring meeting Meet anytime
        Join Zoom Meeting
        https://us02web.zoom.us/j/83073322781?pwd=7fn353ubGTimVK06niSVV68GQyzoS9.1
        Meeting ID: 830 7332 2781
        Passcode: 918859
        ---
        One tap mobile
        +13052241968,,83073322781#,,,,*918859# US
        +13092053325,,83073322781#,,,,*918859# US
        ---
        Dial by your location
        • +1 305 224 1968 US
        • +1 309 205 3325 US
        • +1 312 626 6799 US (Chicago)
        • +1 646 558 8656 US (New York)
        • +1 646 931 3860 US
        • +1 301 715 8592 US (Washington DC)
        • +1 346 248 7799 US (Houston)
        • +1 360 209 5623 US
        • +1 386 347 5053 US
        • +1 507 473 4847 US
        • +1 564 217 2000 US
        • +1 669 444 9171 US
        • +1 669 900 9128 US (San Jose)
        • +1 689 278 1000 US
        • +1 719 359 4580 US
        • +1 253 205 0468 US
        • +1 253 215 8782 US (Tacoma)
        Meeting ID: 830 7332 2781
        Passcode: 918859
        Find your local number: https://us02web.zoom.us/u/kcQxGDqq9m`
    },
    upcomingEvents: [
        {
            month: "January 2025",
            events: [
                {
                    title: "MA Specific: Module 4 - Special Education Part II",
                    date: {
                        dayAndTime: "Wednesday, 12:30–2:30 PM EDT",
                        dayNumber: "29",
                        month: "Jan"
                    },
                    description: `In Module 4 you will build upon modules 1-3 and cover the unique MTSS considerations for Newcomer and SLIFE learners. This module includes better understanding where Newcomer and SLIFE learners fit into the Special Education process, how the data from identification system builds a stronger foundation for determining evaluations or not, and what frameworks should be used when making these decisions.`
                },
            ]
        },
        {
            month: "February 2025",
            events: [
                {
                    title: "MA Specific: Module 5 - Progress Monitoring",
                    date: {
                        dayAndTime: "Wednesday, 9:00–11:00 AM EDT",
                        dayNumber: "5",
                        month: "Feb"
                    },
                    description: `Module 5 continues building on modules 1-4 looking at the big picture of identification data and how it can be used towards a more cohesive structure for progress monitoring.`
                },
                {
                    title: "Addressing the Social and Emotional Needs of SLIFE Students",
                    date: {
                        dayAndTime: "Thursday, 9:00–11:00 AM EDT",
                        dayNumber: "27",
                        month: "Feb"
                    },
                    description: `Guest Speakers Dr. Vanja Pejic and Dr. Jeffrey Winer`
                },
            ]
        },
        {
            month: "March 2025",
            events: [
                {
                    title: "MA Specific: Module 6 - Low Enrollment Areas Part I",
                    date: {
                        dayAndTime: "Wednesday, 12:30-2:30 PM EDT",
                        dayNumber: "12",
                        month: "Mar"
                    },
                    description: `These modules are focused on the entire identification and support process for districts who have lower enrollment of
                    SLIFE learners and are unable to create the same type of programs as larger districts.`
                },
                {
                    title: "MA Specific: Module 6 - Low Enrollment Areas Part II",
                    date: {
                        dayAndTime: "Wednesday, 12:30-2:30 PM EDT",
                        dayNumber: "26",
                        month: "Mar"
                    },
                    description: `These modules are focused on the entire identification and support process for districts who have lower enrollment of
                    SLIFE learners and are unable to create the same type of programs as larger districts.`
                },
            ]
        },
        {
            month: "April 2025",
            events: [
                {
                    title: "MA Specific: Module 7 - Low Enrollment Areas Continued Part I",
                    date: {
                        dayAndTime: "Wednesday, 9:00–11:00 AM EDT",
                        dayNumber: "23",
                        month: "Apr"
                    },
                    description: `These modules are focused on the entire identification and support process for districts who have lower enrollment of
                    SLIFE learners and are unable to create the same type of programs as larger districts.`
                },
            ]
        },
        {
            month: "May 2025",
            events: [
                {
                    title: "MA Specific: Module 7 - Low Enrollment Areas Continued Part II",
                    date: {
                        dayAndTime: "Wednesday, 9:00–11:00 AM EDT",
                        dayNumber: "7",
                        month: "May"
                    },
                    description: `These modules are focused on the entire identification and support process for districts who have lower enrollment of
                    SLIFE learners and are unable to create the same type of programs as larger districts.`
                },
                {
                    title: "MA Specific: Module 8 - Lasting Change Part I",
                    date: {
                        dayAndTime: "Wednesday, 12:30–2:30 PM EDT",
                        dayNumber: "21",
                        month: "May"
                    },
                    description: `In this module we build action plans for the following school year to be implemented. You will graduate from the
                    Community of Action into our national COA community and learn how you can leverage this community and Inlier
                    moving forward for SLIFE + Newcomer identification and support.`
                },
                {
                    title: "MA Specific: Module 8 - Lasting Change Part II",
                    date: {
                        dayAndTime: "Wednesday, 12:30–2:30 PM EDT",
                        dayNumber: "11",
                        month: "June"
                    },
                    description: `In this module we build action plans for the following school year to be implemented. You will graduate from the
                    Community of Action into our national COA community and learn how you can leverage this community and Inlier
                    moving forward for SLIFE + Newcomer identification and support.`
                },
            ]
        },
    ]
};