import React from 'react';
import { Box, IconButton, Tooltip, Typography, Dialog, DialogActions, DialogContent, DialogContentText, Button } from '@mui/material';
import CommentIcon from '@mui/icons-material/Comment';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

export const PostActions = ({
    showComments,
    commentCount,
    handleShowComments,
    handleEditToggle,
    handleDelete,
    deleteDialogOpen,
    setDeleteDialogOpen,
    canEdit
}) => {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 2 }}>
            <IconButton size="small" onClick={handleShowComments}>
                <CommentIcon fontSize="small" />
            </IconButton>
            <Typography
                variant="caption"
                color="textSecondary"
                sx={{ marginLeft: 1, cursor: 'pointer' }}
                onClick={handleShowComments}
            >
                {commentCount} {commentCount === 1 ? 'comment' : 'comments'}
            </Typography>
            {/* Edit and Delete buttons only visible if the user is the author */}
            {canEdit && (
                <>
                    <Tooltip title="Edit Post">
                        <IconButton size="small" onClick={handleEditToggle} sx={{ marginLeft: 'auto' }}>
                            <EditIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete Post">
                        <IconButton size="small" onClick={() => setDeleteDialogOpen(true)}>
                            <DeleteIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </>
            )}

            {/* Delete Confirmation Dialog */}
            <Dialog
                open={deleteDialogOpen}
                onClose={() => setDeleteDialogOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this post? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteDialogOpen(false)} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleDelete} color="primary" autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};
