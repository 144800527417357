import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

export const StateProtectedRoute = ({ accessToken, requiredState }) => {
    const userType = window.localStorage.getItem('userType'); // "true" or "false"
    const userState = window.localStorage.getItem('state');   // user's state from localStorage

    // If no accessToken or userType, redirect to login
    if (!accessToken) {
        return <Navigate to="/" />;
    }

    // If userType is "true" (Inlier team), allow access
    if (userType === "true") {
        return <Outlet />;
    }

    // If the user's state matches the requiredState, allow access
    if (userState && userState.toLowerCase() === requiredState.toLowerCase()) {
        return <Outlet />;
    }

    // If the user doesn't have access, redirect to a "No Access" page or show an error
    return <Navigate to="/no-access" />;
};