import React, { useState } from 'react';
import { CardContent, Typography, Box, IconButton } from '@mui/material';
import { StyledCreatePostCard } from "../styles/useStyles";
import AddIcon from '@mui/icons-material/Add';
import { UserAvatar } from './Sidebar/UserAvatar';
import { CreatePostModal } from './CreatePostModal';

export const CreatePostBox = ({ onCreatePost, groupId }) => {
    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <>
            <StyledCreatePostCard onClick={handleOpen}>
                <UserAvatar />
                <CardContent sx={{ flexGrow: 1 }}>
                    <Typography variant="body1" color="textSecondary">
                        Create a new post
                    </Typography>
                </CardContent>
                <Box>
                    <IconButton>
                        <AddIcon />
                    </IconButton>
                </Box>
            </StyledCreatePostCard>
            {/* Modal for Creating a Post */}
            <CreatePostModal open={open} handleClose={handleClose} onCreatePost={onCreatePost} groupId={groupId} />
        </>
    );
};