import React from 'react';
import { Box, Grid } from '@mui/material';
import { PageTitleBar } from '../components/PageTitleBar';
import { HeaderSection } from '../components/HeaderSection';
import { SubsectionCard, StyledCardContent, SubsectionTitle, SubsectionText } from '../styles/useStyles';

export const Abee = () => {
    return (
        <Box>
            {/* Page Title Bar */}
            <PageTitleBar icon="🛠️" title="ABEE" />
            {/* Header Section */}
            <HeaderSection title="ABEE" />
            {/* Subsections */}
            <Box sx={{ paddingTop: "2rem", display: 'flex', alignItems: 'center' }}>
                <Grid container spacing={3} justifyContent="center" alignItems="center">
                    <Grid>
                        <a href="https://inlierlearning.notion.site/abee-toolkit"
                            target="_blank" rel="noopener noreferrer"
                            style={{ textDecoration: 'none' }}>
                            <SubsectionCard>
                                <StyledCardContent>
                                    <SubsectionTitle>
                                        ABEE
                                    </SubsectionTitle>
                                    <SubsectionText>
                                        Explore ABEE
                                    </SubsectionText>
                                </StyledCardContent>
                            </SubsectionCard>
                        </a>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};