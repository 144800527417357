import { api } from './axios';
import { jwtDecode } from 'jwt-decode';

export const checkAndRefreshToken = async () => {
    const accessToken = window.localStorage.getItem("iat");

    if (!accessToken) {
        return false; // No access token, redirect to login
    }

    const { exp } = jwtDecode(accessToken);

    if (Date.now() >= exp * 1000) {
        try {
            // Token has expired, refresh it
            const response = await api.post('/refresh_token', {}, {
                withCredentials: true,
                headers: {
                    Accept: "application/json",
                },
            });
            // Save the new token
            window.localStorage.setItem("iat", response.data.accessToken);
            return true;
        } catch (error) {
            console.warn("Unable to refresh token. Please log in again.");
            window.localStorage.removeItem("iat");  // Clear expired token
            return false;
        }
    }
    return true; // Token is still valid
};
