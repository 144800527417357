import React, { useEffect, useState, useCallback } from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { AllRoutes } from "./routes/AllRoutes";
import { api } from "./helpers/axios";
import { jwtDecode } from "jwt-decode"; // No need for destructuring
import { Login } from './pages/Login';
import { Sidebar } from './components/Sidebar/Sidebar';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MainContent } from './styles/useStyles';
import { SetPassword } from './pages/SetPassword';
import { postsApiHelpers } from "./helpers/postsApiHelpers";

const AppContent = ({ userType }) => {
  const [loading, setLoading] = useState(true);
  const [accessToken, setAccessToken] = useState(window.localStorage.getItem("iat"));
  const location = useLocation(); // Get the current route
  const [newPostsCount, setNewPostsCount] = useState([]);
  const [notifications, setNotifications] = useState([]);

  const fetchNewPosts = useCallback(() => {
    const getNewPostsCount = async () => {
      try {
        const newInfo = await postsApiHelpers.fetchNewPostsCount();
        setNewPostsCount(newInfo.newPosts);
        setNotifications(newInfo.notifications);
      } catch (err) {
        console.error("Failed to fetch new posts count:", err);
      }
    };
    getNewPostsCount();
  }, []);

  const isAbeePage = location.pathname === '/abee'; // Check if the current route is "/abee"

  useEffect(() => {
    const token = window.localStorage.getItem("iat");
    if (token) {
      setAccessToken(token);
    }
  }, [setAccessToken]);

  useEffect(() => {
    if (!accessToken) {
      setLoading(false);
      return;
    } else {
      const { exp } = jwtDecode(accessToken);
      if (Date.now() >= exp * 1000) {
        api
          .post(`/refresh_token`, {}, {
            withCredentials: true,
            headers: {
              Accept: "application/json",
            },
          })
          .then(async x => {
            window.localStorage.setItem("iat", x.data.accessToken);
            setAccessToken(x.data.accessToken);
            setLoading(false);
          })
          .catch(err => {
            console.warn("Your refresh token is invalid. Try to relogin");
            console.error(err);
            window.localStorage.removeItem("iat");  // Clear expired token
            setLoading(false);
          });
      } else {
        setLoading(false);
      }
    }
  }, [accessToken]);

  useEffect(() => {
    if (accessToken) {
      fetchNewPosts();
    }
  }, [location, accessToken, fetchNewPosts]);

  const handleNewPostCount = () => {
    fetchNewPosts();
  };

  if (loading) {
    return <div>loading...</div>;
  }

  // Show Login for all pages except "ABEE" if the user is not authenticated
  if (!accessToken && !isAbeePage) {
    return <Login />;
  }

  // Don't show the sidebar for OnboardingPasswordChange page
  const hideSidebar = location.pathname === '/onboarding-password-change';

  return (
    <div>
      {!hideSidebar && <Sidebar userType={userType} newPostsCount={newPostsCount} notifications={notifications} />}
      <MainContent>
        <AllRoutes accessToken={accessToken} handleNewPostCount={handleNewPostCount} />
      </MainContent>
    </div>
  );
};

export const App = () => {
  const userType = 'admin'; // Assuming the userType is set elsewhere

  return (
    <BrowserRouter>
      <Routes>
        {/* Public Route */}
        <Route path="/" element={<Login />} />
        <Route path="/setpassword/:userId/:token" element={<SetPassword />} />  {/* Public Route for password reset */}
        {/* Authenticated Routes */}
        <Route path="/*" element={<AppContent userType={userType} />} />
      </Routes>
      <ToastContainer position="top-center" />
    </BrowserRouter>
  );
};
