import axios from "axios";

export const api = axios.create({
  //baseURL: 'http://localhost:5000',
  baseURL: "https://api.inlierlearning.com/",
  withCredentials: true,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});
