import React from 'react';
import { Box, Grid } from '@mui/material';
import { PageTitleBar } from '../components/PageTitleBar';
import { HeaderSection } from '../components/HeaderSection';
import { SubsectionCard, StyledCardContent, SubsectionTitle, SubsectionText } from '../styles/useStyles';

export const StartHere = () => {
    return (
        <Box>
            {/* Page Title Bar */}
            <PageTitleBar icon="🚀" title="Start Here" />
            {/* Header Section */}
            <HeaderSection title="Start Here" />
            {/* Subsections */}
            <Box sx={{ paddingTop: "2rem", display: 'flex', alignItems: 'center' }}>
                <Grid container spacing={3} justifyContent="center" alignItems="center">
                    <Grid item xs={12} sm={6} md={6}>
                        <a href="https://inlierlearning.notion.site/Community-Best-Practices-1077d1bbc29d80d786c5f192810c196f"
                            target="_blank" rel="noopener noreferrer"
                            style={{ textDecoration: 'none' }}>
                            <SubsectionCard>
                                <StyledCardContent>
                                    <SubsectionTitle>
                                        Community Best Practices
                                    </SubsectionTitle>
                                    <SubsectionText>
                                        Learn about the guiding principles to follow for collective success.
                                    </SubsectionText>
                                </StyledCardContent>
                            </SubsectionCard>
                        </a>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};
