import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Login } from '../pages/Login';
import { ProtectedRoute } from '../components/ProtectedRoute';
import { StartHere } from '../pages/StartHere';
import { UpcomingEvents } from '../pages/UpcomingEvents';
import { NotFound } from '../pages/NotFound';
import { SlifePolicy } from '../pages/CommunityThinktank/SlifePolicy';
import { AskInlier } from '../pages/CommunityThinktank/AskInlier';
import { Assessment } from '../pages/CommunityThinktank/Assessment';
import { Identification } from '../pages/CommunityThinktank/Identification';
import { SpecialEducation } from '../pages/CommunityThinktank/SpecialEducation';
import { Math } from '../pages/CommunityThinktank/Math';
import { Reading } from '../pages/CommunityThinktank/Reading';
import { TraumaSupport } from '../pages/CommunityThinktank/TraumaSupport';
import { BehavioralSupport } from '../pages/CommunityThinktank/BehavioralSupport';
import { Grading } from "../pages/CommunityThinktank/Grading";
import { Wins } from "../pages/ForYou/Wins";
import { Challenges } from "../pages/ForYou/Challenges";
import { Massachusetts } from "../pages/States/Massachusetts";
import { MAUpcomingEvents } from '../pages/States/MAUpcomingEvents';
import { Connecticut } from "../pages/States/Connecticut";
import { Idaho } from "../pages/States/Idaho";
import { NewHampshire } from "../pages/States/NewHampshire";
import { StateProtectedRoute } from "../components/StateProtectedRoute";
import { NoAccess } from '../pages/NoAccess';
import { PastEvents } from '../pages/PastEvents';
import { MAPastEvents } from '../pages/States/MAPastEvents';
import { Announcements } from '../pages/Announcements';
import { Settings } from '../pages/Settings';
import { SetPassword } from '../pages/SetPassword';
import { OnboardingPasswordChange } from '../pages/OnboardingPasswordChange';
import { Maryland } from '../pages/States/Maryland';
import { Abee } from '../pages/Abee';

export const AllRoutes = ({ accessToken, handleNewPostCount }) => {
    return (
        <Routes>
            {/* Public Route */}
            <Route path="/" element={<Login />} />
            <Route path="/setpassword/:userId/:token" element={<SetPassword />} />
            <Route path="/abee" element={<Abee />} />
            {/* Protected Routes */}
            <Route element={<ProtectedRoute accessToken={accessToken} />}>
                <Route path="/onboarding-password-change" element={<OnboardingPasswordChange />} />
                <Route path="/start-here" element={<StartHere />} />
                <Route path="/upcoming-events" element={<UpcomingEvents />} />
                <Route path="/past-events" element={<PastEvents />} />
                <Route path="/announcements" element={<Announcements handleNewPostCount={handleNewPostCount} />} />
                <Route path="/settings" element={<Settings />} />
                {/* Community Thinktank */}
                <Route path="/ask-inlier" element={<AskInlier handleNewPostCount={handleNewPostCount} />} />
                <Route path="/slife-policy" element={<SlifePolicy handleNewPostCount={handleNewPostCount} />} />
                <Route path="/assessment" element={<Assessment />} handleNewPostCount={handleNewPostCount} />
                <Route path="/identification" element={<Identification handleNewPostCount={handleNewPostCount} />} />
                <Route path="/special-education" element={<SpecialEducation handleNewPostCount={handleNewPostCount} />} />
                <Route path="/math" element={<Math handleNewPostCount={handleNewPostCount} />} />
                <Route path="/reading" element={<Reading handleNewPostCount={handleNewPostCount} />} />
                <Route path="/trauma-support" element={<TraumaSupport handleNewPostCount={handleNewPostCount} />} />
                <Route path="/behavioral-support" element={<BehavioralSupport handleNewPostCount={handleNewPostCount} />} />
                <Route path="/grading" element={<Grading handleNewPostCount={handleNewPostCount} />} />
                <Route path="/wins" element={<Wins handleNewPostCount={handleNewPostCount} />} />
                <Route path="/challenges" element={<Challenges handleNewPostCount={handleNewPostCount} />} />
                {/* State-specific protected routes */}
                <Route element={<StateProtectedRoute accessToken={accessToken} requiredState="Massachusetts" />}>
                    <Route path="/state/massachusetts" element={<Massachusetts handleNewPostCount={handleNewPostCount} />} />
                </Route>
                <Route element={<StateProtectedRoute accessToken={accessToken} requiredState="Massachusetts" />}>
                    <Route path="/ma-upcoming-events" element={<MAUpcomingEvents />} />
                </Route>
                <Route element={<StateProtectedRoute accessToken={accessToken} requiredState="Massachusetts" />}>
                    <Route path="/ma-past-events" element={<MAPastEvents />} />
                </Route>
                <Route element={<StateProtectedRoute accessToken={accessToken} requiredState="Connecticut" />}>
                    <Route path="/state/connecticut" element={<Connecticut handleNewPostCount={handleNewPostCount} />} />
                </Route>
                <Route element={<StateProtectedRoute accessToken={accessToken} requiredState="Idaho" />}>
                    <Route path="/state/idaho" element={<Idaho handleNewPostCount={handleNewPostCount} />} />
                </Route>
                <Route element={<StateProtectedRoute accessToken={accessToken} requiredState="Maryland" />}>
                    <Route path="/state/maryland" element={<Maryland handleNewPostCount={handleNewPostCount} />} />
                </Route>
                <Route element={<StateProtectedRoute accessToken={accessToken} requiredState="New Hampshire" />}>
                    <Route path="/state/newhampshire" element={<NewHampshire handleNewPostCount={handleNewPostCount} />} />
                </Route>
                {/* Add more protected routes here */}
            </Route>
            {/* No Access Route */}
            <Route path="/no-access" element={<NoAccess />} />
            {/* Fallback Route for Non-Existent URLs */}
            <Route path="*" element={<NotFound />} />
        </Routes>
    );
};
