import React from 'react';
import { Box, Typography, Divider } from '@mui/material';
import { CommentList } from '../Comment/CommentList';
import { CommentInputBox } from '../Comment/CommentInputBox';

export const PostCommentSection = ({
    postId,
    comments,
    loadingComments,
    accessToken,
    handleUpdateComment,
    handleDeleteComment,
    onNewComment // Add the onNewComment handler here
}) => {
    return (
        <Box sx={{ marginTop: 2 }}>
            <Divider
                sx={{
                    marginBottom: 2,
                    borderWidth: '1.5px',
                    borderColor: '#3f51b5',
                }}
            />
            {loadingComments ? (
                <Typography variant="body2" color="textSecondary">
                    Loading comments...
                </Typography>
            ) : (
                <CommentList
                    comments={comments}
                    onUpdateComment={handleUpdateComment}
                    onDeleteComment={handleDeleteComment}
                />
            )}
            <CommentInputBox
                postId={postId}
                accessToken={accessToken}
                onNewComment={onNewComment} // Pass the handleNewComment handler
            />
        </Box>
    );
};
