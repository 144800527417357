import moduleone from '../styles/images/moduleone.png';

export const upcomingEventsData = {
    featuredEvent: {
        title: "Addressing the Social and Emotional Needs of SLIFE Students",
        date: {
            dayAndTime: "Monday, Jan 27, 1:00–3:00 PM EDT",
            dayNumber: "27",
            month: "Jan"
        },
        description: `Guest Speakers Dr. Vanja Pejic and Dr. Jeffrey Winer`,
        image: moduleone,
        zoomLink: `https://us02web.zoom.us/j/81577147438?pwd=a3VVYUBHx522S8naEhbZLYBZDbkbOE.1`,
        zoomDetails: `Orly Klapholz (she/her) is inviting you to a scheduled Zoom meeting.
            Topic: COA Module
            Time: This is a recurring meeting Meet anytime
            Join Zoom Meeting
            https://us02web.zoom.us/j/81577147438?pwd=a3VVYUBHx522S8naEhbZLYBZDbkbOE.1
            Meeting ID: 815 7714 7438
            Passcode: 222194
            ---
            One tap mobile
            +13092053325,,81577147438#,,,,*222194# US
            +13126266799,,81577147438#,,,,*222194# US (Chicago)
            ---
            Dial by your location
            • +1 309 205 3325 US
            • +1 312 626 6799 US (Chicago)
            • +1 646 558 8656 US (New York)
            • +1 646 931 3860 US
            • +1 301 715 8592 US (Washington DC)
            • +1 305 224 1968 US
            • +1 507 473 4847 US
            • +1 564 217 2000 US
            • +1 669 444 9171 US
            • +1 669 900 9128 US (San Jose)
            • +1 689 278 1000 US
            • +1 719 359 4580 US
            • +1 253 205 0468 US
            • +1 253 215 8782 US (Tacoma)
            • +1 346 248 7799 US (Houston)
            • +1 360 209 5623 US
            • +1 386 347 5053 US
            Meeting ID: 815 7714 7438
            Passcode: 222194
            Find your local number: https://us02web.zoom.us/u/ketvn0k7l0`
    },
    upcomingEvents: [
        {
            month: "January 2025",
            events: [
                {
                    title: "Addressing the Social and Emotional Needs of SLIFE Students",
                    date: {
                        dayAndTime: "Monday, 1:00–3:00 PM EDT",
                        dayNumber: "27",
                        month: "Jan"
                    },
                    description: `Guest Speakers Dr. Vanja Pejic and Dr. Jeffrey Winer`
                },
            ]
        },
        {
            month: "February 2025",
            events: [
                {
                    title: "Module 5 - Graduation Part I",
                    date: {
                        dayAndTime: "Monday, 9:30–11:30 AM EDT",
                        dayNumber: "3",
                        month: "Feb"
                    },
                    description: `Module 5 continues building on modules 1-4 looking at the big picture of identification data and how it can be used to support higher rates of graduation for Newcomer and SLIFE learners. In this module we look at data, school programs, and district wide programs and evaluate what’s working, what’s missing, and create a plan that allows you to move towards a more cohesive structure from enrollment to graduation.`
                },
                {
                    title: "Module 5 - Graduation Part II",
                    date: {
                        dayAndTime: "Monday, 9:30–11:30 AM EDT",
                        dayNumber: "24",
                        month: "Feb"
                    },
                    description: `Module 5 continues building on modules 1-4 looking at the big picture of identification data and how it can be used to support higher rates of graduation for Newcomer and SLIFE learners. In this module we look at data, school programs, and district wide programs and evaluate what’s working, what’s missing, and create a plan that allows you to move towards a more cohesive structure from enrollment to graduation.`
                },
            ]
        },
        {
            month: "March 2025",
            events: [
                {
                    title: "Module 6 - Low Enrollment Areas Part I",
                    date: {
                        dayAndTime: "Monday, 1:00–3:00 PM EDT",
                        dayNumber: "10",
                        month: "Mar"
                    },
                    description: `These modules are focused on the entire identification and support process for districts who have lower enrollment of
                    SLIFE learners and are unable to create the same type of programs as larger districts.`
                },
                {
                    title: "Module 6 - Low Enrollment Areas Part II",
                    date: {
                        dayAndTime: "Monday, 1:00–3:00 PM EDT",
                        dayNumber: "24",
                        month: "Mar"
                    },
                    description: `These modules are focused on the entire identification and support process for districts who have lower enrollment of
                    SLIFE learners and are unable to create the same type of programs as larger districts.`
                },
            ]
        },
        {
            month: "April 2025",
            events: [
                {
                    title: "Module 7 - Low Enrollment Areas Continued Part I",
                    date: {
                        dayAndTime: "Monday, 9:30–11:30 AM EDT",
                        dayNumber: "7",
                        month: "Apr"
                    },
                    description: `These modules are focused on the entire identification and support process for districts who have lower enrollment of
                    SLIFE learners and are unable to create the same type of programs as larger districts.`
                },
                {
                    title: "Module 7 - Low Enrollment Areas Continued Part II",
                    date: {
                        dayAndTime: "Monday, 9:30–11:30 AM EDT",
                        dayNumber: "28",
                        month: "Apr"
                    },
                    description: `These modules are focused on the entire identification and support process for districts who have lower enrollment of
                    SLIFE learners and are unable to create the same type of programs as larger districts.`
                },
            ]
        },
        {
            month: "May 2025",
            events: [
                {
                    title: "Module 8 - Lasting Change Part I",
                    date: {
                        dayAndTime: "Monday, 1:00–3:00 PM EDT",
                        dayNumber: "12",
                        month: "May"
                    },
                    description: `In this module we build action plans for the following school year to be implemented. You will graduate from the
                    Community of Action into our national COA community and learn how you can leverage this community and Inlier
                    moving forward for SLIFE + Newcomer identification and support.`
                },
                {
                    title: "Module 8 - Lasting Change Part II",
                    date: {
                        dayAndTime: "Tuesday, 1:00–3:00 PM EDT",
                        dayNumber: "27",
                        month: "May"
                    },
                    description: `In this module we build action plans for the following school year to be implemented. You will graduate from the
                    Community of Action into our national COA community and learn how you can leverage this community and Inlier
                    moving forward for SLIFE + Newcomer identification and support.`
                },
            ]
        },
    ]
};