import styled from '@emotion/styled';
import { styled as MuiStyled } from '@mui/material/styles';
import {
  Container as MuiContainer,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Grid as MuiGrid,
  IconButton as MuiIconButton,
  ListItemButton as MuiListItemButton,
  Box as MuiBox,
  TextField as MuiTextField,
  Button as MuiButton,
  ListItem as MuiListItem,
  Avatar,
  MenuItem,
  Menu,
  Typography
} from '@mui/material';

export const drawerWidth = 296;

export const Container = styled(MuiContainer)`
  display: flex;
  background-color: #E5E8E9;
  height: 100vh;
`;

export const MainContent = styled('main')`  
  min-height: calc(100vh - 56px); // Ensure it covers the full height minus the top nav height
  flex-grow: 1;
  background-color: #F6F9FA;
  margin-left: ${drawerWidth}px;
  margin-top: 56px; // Adjust for the fixed top nav bar height
  transition: margin-left 0.3s ease;
  @media (max-width: 899px) {
    margin-left: 0;
    padding-top: 56px; // Height of the TopNavBar
  }
`;

export const Loader = styled('div')`
  text-align: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const Card = styled(MuiCard)`
  margin-bottom: 16px;
`;

export const CardContent = styled(MuiCardContent)`
  padding: 16px;
`;

export const Grid = styled(MuiGrid)`
  padding: 24px;
`;

export const HomeTitle = styled('h1')`
  margin-bottom: 24px;
  font-size: 2rem;
`;

export const LoginContainer = styled(MuiContainer)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

export const Form = styled('div')`
  display: flex;
  flex-direction: column;
  width: 300px;
  gap: 16px;
`;

export const MobileMenuButton = styled(MuiIconButton)`
  display: none;
  @media (max-width: 899px) {
    display: block;
  }
`;

export const TopNavBar = styled('div')`
  display: none;
  @media (max-width: 899px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 56px;
    background-color: white; // Change background color to white
    z-index: 1300; // Ensure it appears above other elements
    padding: 0 16px;
    border-bottom: 1px solid #E0E0E0; // Add border bottom
  }
`;

export const TopNavBarTitle = styled('div')`
  color: black; // Change text color to black
  font-size: 1.5em;
`;

export const TopBar = styled('div')`
  width: 100%;
  height: 56px;
  background-color: white; // Change background color to white
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 16px;
  border-bottom: 1px solid #E0E0E0; // Add border bottom
  position: fixed; // Make the top bar fixed
  top: 0; // Fix it to the top
  left: 0; // Fix it to the left
  z-index: 1300; // Ensure it appears above other elements
`;

export const UserIconButton = styled(MuiIconButton)`
  color: black; // Change icon color to black
`;

export const CustomAvatar = styled(Avatar)(({ bgColor }) => ({
  backgroundColor: bgColor,
  color: 'white',
  width: 40,
  height: 40,
}));

export const CustomMenuItem = styled(MenuItem)`
  font-family: system-ui, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Segoe UI", Roboto, Helvetica, Arial, sans-serif !important;
  font-size: 14px !important;
  font-weight: 300 !important;
  line-height: 20px !important;
  color: rgb(84, 88, 97) !important;
  &:hover {
    background-color: #F1F4F5;
  }
`;

export const CustomMenu = styled(Menu)`
  font-family: system-ui, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Segoe UI", Roboto, Helvetica, Arial, sans-serif !important;
  font-size: 14px !important;
  font-weight: 300 !important;
  line-height: 20px !important;
  color: rgb(84, 88, 97) !important;
  border-bottom-color: rgb(228, 231, 235);
  border-bottom-style: solid;
  border-bottom-width: 0px;
  border-left-color: rgb(228, 231, 235);
  border-left-style: solid;
  border-left-width: 0px;
  border-right-color: rgb(228, 231, 235);
  border-right-style: solid;
  border-right-width: 0px;
  border-top-color: rgb(228, 231, 235);
  border-top-style: solid;
  border-top-width: 0px;
  cursor: pointer;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
`;

export const SidebarCustomListItemButton = MuiStyled(MuiListItemButton)(({ theme, active }) => ({
  justifyContent: 'space-between',
  backgroundColor: active ? 'black' : 'transparent',
  color: active ? 'white' : 'inherit',
  borderRadius: active ? '8px' : '0',
  margin: '0 12px',
  '&:hover': {
    backgroundColor: active ? 'black' : theme.palette.action.hover,
  },
  '& .MuiSvgIcon-root': {
    opacity: 0,
    transition: 'opacity 0.3s ease',
  },
  '&:hover .MuiSvgIcon-root': {
    opacity: 1,
  },
  '&:hover .MuiListSubheader-root': {
    backgroundColor: 'transparent',
  },
}));

// Styling for the last list item button to add bottom margin
export const SidebarLastListItemButton = MuiStyled(SidebarCustomListItemButton)({
  marginBottom: '30px',
});

export const SidebarContainer = styled(MuiBox)(({ theme }) => ({
  position: 'fixed',
  top: 56,
  left: 0,
  width: `${drawerWidth}px`,
  height: '93vh', // Set a smaller height to create more overflow
  overflowY: 'hidden', // Initially hide the scrollbar
  scrollbarWidth: 'thin',
  scrollbarColor: `light gray transparent`,
  transition: 'overflow-y 0.3s ease', // Smooth transition for the scrollbar appearance
  '&:hover': {
    overflowY: 'auto', // Show scrollbar on hover
  },
  '&::-webkit-scrollbar': {
    width: '6px',
  },
  '&::-webkit-scrollbar-track': {
    background: 'transparent',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.primary.main,
    borderRadius: '10px',
    border: '2px solid transparent',
    backgroundClip: 'content-box',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));


export const SubsectionCard = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',  // Ensure content is evenly distributed
  alignItems: 'center',             // Center align content
  backgroundColor: '#f5faff',
  backgroundImage: 'linear-gradient(145deg, #f5faff, #f8f8f8)', // Subtle gradient for depth
  borderRadius: '12px',  // Rounded corners
  padding: theme.spacing(2),  // Consistent padding
  textAlign: 'center',  // Centered text
  boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',  // Soft shadow for elevation
  transition: 'transform 0.3s ease, box-shadow 0.3s ease',  // Smooth transition for hover effects
  height: '100%',  // Ensure full height within the grid
  minHeight: '200px',  // Set a minimum height to ensure consistency
  '&:hover': {
    transform: 'translateY(-3px)',  // Slight lift on hover
    boxShadow: '0px 8px 30px rgba(0, 0, 0, 0.15)',  // Enhanced shadow on hover
  },
  textDecoration: 'none',  // Remove underline from links
  color: 'inherit',  // Inherit text color
}));

export const StyledCardContent = styled(MuiBox)(({ theme }) => ({
  padding: theme.spacing(3),
  flexGrow: 1,  // Ensure content fills the available space
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',  // Center content vertically
  alignItems: 'center',  // Center content horizontally
}));

export const SubsectionTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  fontSize: '1.25rem',  // Slightly larger font size for emphasis
  marginBottom: theme.spacing(1),  // Spacing below the title
  color: theme.palette.text.primary,  // Consistent color with the theme
}));

export const SubsectionText = styled(Typography)(({ theme }) => ({
  fontSize: '1rem',
  color: theme.palette.text.secondary,  // Lighter color for body text
}));


export const StyledHeaderSection = styled(MuiBox)(({ theme }) => ({
  backgroundColor: '#EDE7F6',
  backgroundImage: 'linear-gradient(45deg, #EDE7F6 50%, #D1C4E9 50%)',
  height: '300px',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingLeft: "7rem",
  paddingTop: "3rem",
  [theme.breakpoints.down('md')]: {
    paddingLeft: theme.spacing(4),
    paddingTop: theme.spacing(3),
    height: '250px',
  },
  [theme.breakpoints.down('sm')]: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
    height: '200px',
  },
}));

export const StyledPageTitleBar = styled(MuiBox)(({ theme }) => ({
  backgroundColor: 'white',
  padding: theme.spacing(2, 4),
  display: 'flex',
  alignItems: 'center',
  position: 'fixed',
  top: 56,
  left: drawerWidth,
  width: `calc(100% - ${drawerWidth}px)`,
  zIndex: 10,
  borderLeft: "solid #E0E0E0 1px",
  borderBottom: "solid #E0E0E0 1px",
  transition: 'left 0.3s ease, width 0.3s ease',
  [theme.breakpoints.down('md')]: {
    left: 0,
    width: '100%',
  },
}));



export const StyledPostCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[1], // Increase shadow for better elevation
  borderRadius: theme.shape.borderRadius * 2, // Smooth border radius
  padding: theme.spacing(3),
  maxWidth: '800px',
  margin: '0 auto',
  marginBottom: theme.spacing(4),
  '& .MuiTypography-h6': {
    color: theme.palette.text.primary, // Primary text color for the author name
  },
  '& .MuiTypography-subtitle1': {
    color: theme.palette.text.primary, // Primary text color for the author name
  },
  '& .MuiTypography-caption': {
    color: theme.palette.text.secondary, // Secondary text color for the date
  },
  '& .MuiIconButton-root': {
    color: theme.palette.text.secondary, // Icon color
  },
}));

export const PinIcon = styled(MuiIconButton)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(2),
  right: theme.spacing(2),
  cursor: 'default',
  pointerEvents: 'none',
}));

export const PinnedPostCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  margin: '0 auto',
  maxWidth: '800px',
  position: 'relative',
  boxShadow: theme.shadows[1], // Increase shadow for better elevation
  borderRadius: theme.shape.borderRadius * 2, // Smooth border radius
  padding: theme.spacing(3),
  marginBottom: theme.spacing(4),
  '& .MuiTypography-h6': {
    color: theme.palette.text.primary, // Primary text color for the author name
  },
  '& .MuiTypography-subtitle1': {
    color: theme.palette.text.primary, // Primary text color for the author name
  },
  '& .MuiTypography-caption': {
    color: theme.palette.text.secondary, // Secondary text color for the date
  },
  '& .MuiIconButton-root': {
    color: theme.palette.text.secondary, // Icon color
  },
}));

export const PinnedPostContent = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  lineHeight: 1.6,
  marginTop: theme.spacing(4),
}));

export const BoldText = styled('span')(({ theme }) => ({
  fontWeight: 'bold',
}));

export const StyledCreatePostCard = styled(MuiCard)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  maxWidth: '800px',
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[1],
  borderRadius: theme.shape.borderRadius,
  margin: '0 auto',
  marginBottom: '2rem !important',
  padding: theme.spacing(3),
  position: 'relative',
  // border: "solid blue",
}));

export const CreatePostTextField = styled(MuiTextField)(({ theme }) => ({
  flexGrow: 1,
  marginRight: theme.spacing(2),
}));

export const CreatePostButton = styled(MuiIconButton)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

export const StyledModalBox = styled(MuiBox)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%', // Adjust width for mobile
  maxWidth: '600px', // Set a max width for larger screens
  backgroundColor: theme.palette.background.paper,
  borderRadius: '8px', // Optional: make the corners rounded
  boxShadow: 24,
  padding: theme.spacing(4),
  paddingBottom: theme.spacing(6), // Add extra padding to the bottom to ensure space for the button
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(6), // Add extra padding for mobile devices
  },
}));

// Styled Comment ReactQuill component
export const StyledCommentReactQuill = styled('div')(({ theme }) => ({
  height: '80px',
  padding: '0 16px',
  width: '100%',
  boxSizing: 'border-box',
}));

// Styled Comment Box containing the button
export const StyledCommentButtonContainer = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  [theme.breakpoints.down('sm')]: {
    marginTop: '0.5rem',
  },
}));

// Styled Comment Button
export const StyledCommentButton = styled(MuiButton)(({ theme }) => ({
  width: '100px',
  [theme.breakpoints.down('sm')]: {
    width: '80px',
  }
}));

export const MainUpcomingEventsBox = styled(MuiBox)(() => ({
  padding: "3rem",
  paddingTop: "2rem",
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

export const FeaturedUpcomingEventBox = styled(MuiBox)(() => ({
  paddingTop: '3rem'
}));

export const UpcomingEventTypography = styled(Typography)(() => ({
  padding: "1rem",
  fontWeight: 'bold'
}));

export const UpcomingEventCard = styled(MuiCard)(() => ({
  marginBottom: "2rem",
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '800px',
  borderRadius: "8px", // Rounded corners
  boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)' // Subtle shadow for modern look
}));

export const UpcomingEventCardBox = styled(MuiBox)(() => ({
  height: 'auto',
  objectFit: 'cover',
  width: '100%',
  borderRadius: '8px 8px 0 0' // Rounded top corners
}));

export const UpcomingEventCardContent = styled(MuiCardContent)(() => ({
  // border: "solid red",
  height: 'auto',
  padding: "2rem",
  width: '100%'
}));

export const UpcomingEventListBox = styled(MuiBox)(() => ({
  width: '100%',
  maxWidth: '800px'
}));

export const UpcomingEventListInnerBox = styled(MuiBox)(() => ({
  marginBottom: "2rem"
}));

export const UpcomingEventListCard = styled(MuiCard)(() => ({
  marginBottom: "1rem",
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '800px',
  borderRadius: '8px',
  boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.05)' // Softer shadow for the list
}));

export const UpcomingEventListItem = styled(MuiListItem)(() => ({
  padding: '16px',
  borderRadius: '8px',
  display: 'flex',
  alignItems: 'center',
  '&:hover': {
    backgroundColor: '#E3E4F1',
    cursor: 'pointer'
  },
  transition: 'background-color 0.3s ease'
}));

export const UpcomingEventDateBox = styled(MuiBox)(() => ({
  backgroundColor: '#F0F0F0', // Light gray background
  borderRadius: '8px',
  padding: '10px 12px',
  textAlign: 'center',
  marginRight: '16px',
  width: '50px', // Set a fixed width for the box
}));

// export const BadgeBox = styled(MuiBox)(() => ({
//   backgroundColor: '#4CAF50', // Green color for the badge
//   color: 'white',
//   // padding: '2px 6px',
//   borderRadius: '10px',
//   marginLeft: '8px', // Adds a small gap between the name and the badge
//   fontSize: '0.75rem',
//   fontWeight: 'bold',
//   padding: {
//     xs: '6px 6px',  // Smaller padding for mobile (xs)
//     sm: '4px 20px', // Larger padding for tablet and above
//   },
//   fontSize: {
//     xs: '6px',  // Much smaller font size for mobile (xs)
//     sm: '14px', // Larger font size for tablet and above
//   },
//   display: 'inline-block',
//   textAlign: 'center',
//   lineHeight: {
//     xs: '1.2', // Smaller line height for mobile
//     sm: '1.5', // Default line height for tablet and above
//   },
// }));