import { ForumPage } from '../../components/ForumPage';
import { groupUrltoId } from '../../helpers/groupUrltoId';

export const NewHampshire = ({ handleNewPostCount }) => {

    const groupId = groupUrltoId['/state/newhampshire'];

    return (
        <ForumPage title="New Hampshire" icon="📍" groupId={groupId} path="/state/newhampshire" handleNewPostCount={handleNewPostCount} />
    )

};