import { api } from './axios';
import { checkAndRefreshToken } from './checkAndRefreshToken';

export const notificationsApiHelpers = {
    markNotificationAsRead: async (notificationId) => {
        const isTokenValid = await checkAndRefreshToken();
        const accessToken = window.localStorage.getItem("iat");

        if (!isTokenValid || !accessToken) {
            throw new Error('Unable to authenticate');
        }

        // Fix: Move headers to the third argument of axios.put
        const response = await api.put(`/notifications/${notificationId}/read`, {}, { // Pass `null` for data if not sending any
            withCredentials: true,
            headers: {
                "Content-type": "application/json",
                "Authorization": `Bearer ${accessToken}` // Correctly set the Authorization header
            }
        });
        return response.data.message;
    },
};
