import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
    Paper,
    TextField,
    Button,
    Typography,
    Box,
    IconButton,
    InputAdornment,
    CircularProgress
} from "@mui/material";
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { toast } from "react-toastify";
import { api } from "../helpers/axios";
import logoBlack from '../styles/images/logoBlack.png';
import { checkAndRefreshToken } from "../helpers/checkAndRefreshToken";

// Password validation function
const validatePassword = (password) => {
    const minLength = password.length >= 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    return minLength && hasUpperCase && hasLowerCase && hasSpecialChar;
};

export const OnboardingPasswordChange = () => {
    const navigate = useNavigate();
    const [password, setPassword] = useState("");
    const [passwordMatch, setPasswordMatch] = useState("");
    const [success, setSuccess] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [errorText2, setErrorText2] = useState('');
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [loading, setLoading] = useState(false); // Spinner state

    useEffect(() => {
        if (validatePassword(password) && password === passwordMatch) {
            setButtonDisabled(false);
        } else {
            setButtonDisabled(true);
        }
    }, [password, passwordMatch]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (password !== passwordMatch) {
            toast.error("Passwords don't match.");
            return;
        }

        if (!validatePassword(password)) {
            toast.error("Password does not meet the required criteria.");
            return;
        }

        try {
            setLoading(true); // Show spinner

            const isTokenValid = await checkAndRefreshToken();
            const accessToken = window.localStorage.getItem("iat");

            if (!isTokenValid || !accessToken) {
                throw new Error('Unable to authenticate');
            }

            const response = await api.post(`/auth/setonboardingpassword`, { password }, {
                withCredentials: true,
                headers: {
                    "Content-type": "application/json",
                    authorization: `Bearer ${accessToken}`
                }
            });
            if (response && response.data && response.data.status === 200) {
                setSuccess(true);
                toast.success("Password changed successfully. You can now use your new password.");
                navigate("/");
            }
        } catch (err) {
            if (err.response.data.message === "New password cannot be the same as the old password.") {
                toast.error("New password cannot be the same as the old password.");
            } else {
                toast.error("Cannot set new password. Please try again later.");
            }
        } finally {
            setLoading(false); // Hide spinner
        }
    };

    return (
        <Box sx={{
            display: 'flex',
            flexGrow: 1,
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '100vh', // Full viewport height
            backgroundColor: 'white',
            paddingRight: {
                xs: "0px",
                sm: "296px"
            }
        }}>
            <Paper
                elevation={3}
                sx={{
                    padding: "2rem",
                    minWidth: "300px",
                    maxWidth: "500px",
                    textAlign: 'center'
                }}
            >
                {/* Logo */}
                <Box
                    component="img"
                    src={logoBlack}
                    alt="Logo"
                    sx={{
                        width: '150px',
                        marginBottom: '1.5rem',
                    }}
                />

                {/* Welcome Section */}
                <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: '1rem' }}>
                    Welcome to the Community of Action Platform!
                </Typography>
                <Typography variant="body1" sx={{ marginBottom: '1.5rem' }}>
                    We're excited to have you on board. Please set a new password to complete your account setup.
                </Typography>
                <Typography variant="body1" sx={{ marginBottom: '1.5rem' }}>
                    Your password should have at least: 8 characters, a capital letter, a lowercase letter, and a special character.
                </Typography>
                {!success ? (
                    <Box>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="password"
                            label="New Password"
                            name="password"
                            type={showPassword ? "text" : "password"}
                            onChange={(e) => setPassword(e.target.value)}
                            onBlur={() => {
                                if (!validatePassword(password)) {
                                    setErrorText('Password must be at least 8 characters long, contain uppercase and lowercase letters, and include at least one special character.');
                                } else {
                                    setErrorText('');
                                }
                            }}
                            error={errorText.length > 0}
                            helperText={errorText}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => setShowPassword(!showPassword)}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            sx={{ mb: 2, minHeight: '80px' }}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="passwordMatch"
                            label="Confirm New Password"
                            name="passwordMatch"
                            type={showConfirmPassword ? "text" : "password"}
                            onChange={(e) => setPasswordMatch(e.target.value)}
                            onBlur={() => {
                                if (passwordMatch !== password) {
                                    setErrorText2('Passwords do not match.');
                                } else {
                                    setErrorText2('');
                                }
                            }}
                            error={errorText2.length > 0}
                            helperText={errorText2}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                            edge="end"
                                        >
                                            {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            sx={{ minHeight: '80px' }}
                        />
                        <Button
                            disabled={buttonDisabled || loading} // Disable while loading
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2, backgroundColor: '#4A90E2', color: '#fff' }}
                            onClick={handleSubmit}
                        >
                            {loading ? <CircularProgress size={24} color="inherit" /> : 'Set Password'}
                        </Button>
                    </Box>
                ) : (
                    <Box>
                        <Typography variant="h6" sx={{ marginBottom: '1rem' }}>
                            Success! Your password has been updated.
                        </Typography>
                        <Button
                            fullWidth
                            variant="contained"
                            sx={{ backgroundColor: '#4A90E2', color: '#fff' }}
                            onClick={() => navigate("/")}
                        >
                            Login
                        </Button>
                    </Box>
                )}
            </Paper>
        </Box>
    );
};
