import { format, formatDistanceToNow } from 'date-fns';

export const formatTimestamp = (date) => {
    const now = new Date();
    const postDate = new Date(date);
    const differenceInDays = (now - postDate) / (1000 * 60 * 60 * 24);

    if (differenceInDays < 1) {
        // Less than 1 day ago, show relative time (e.g., "16m", "1h")
        return formatDistanceToNow(postDate, { addSuffix: false }).replace('about ', '');
    } else if (differenceInDays < 7) {
        // Less than 7 days ago, show the number of days (e.g., "2d", "6d")
        return `${Math.floor(differenceInDays)}d`;
    } else if (differenceInDays < 30) {
        // Less than 30 days ago, show the day and month (e.g., "Aug 10")
        return format(postDate, 'MMM d');
    } else {
        // More than 30 days ago, show the full date (e.g., "Aug 10, 2024")
        return format(postDate, 'MMM d, yyyy');
    }
};