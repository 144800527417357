import React from 'react';
import { Box } from '@mui/material';

export const InlierTeamBadge = () => {
    return (
        <Box
            sx={{
                marginLeft: '6px',
                marginRight: '6px',
                backgroundColor: '#4CAF50',
                color: '#fff',
                padding: {
                    xs: '1px 4px',  // Smaller padding for mobile (xs)
                    sm: '4px 10px', // Larger padding for tablet and above
                },
                fontSize: {
                    xs: '9px',  // Much smaller font size for mobile (xs)
                    sm: '11px', // Larger font size for tablet and above
                },
                borderRadius: {
                    xs: '8px',
                    sm: '15px',
                },
                display: 'inline-block',
                textAlign: 'center',
                lineHeight: {
                    xs: '1.2', // Smaller line height for mobile
                    sm: '1.5', // Default line height for tablet and above
                },
            }}
        >
            Inlier Team
        </Box>
    );
};
