import React from 'react';
import { Box, Collapse, Divider, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { UpcomingEventListItem, UpcomingEventDateBox } from '../../styles/useStyles';

export const EventItem = ({
    event,
    monthIndex,
    eventIndex,
    expandedEventIndex,
    handleToggle,
    totalEvents // totalEvents is passed here
}) => {
    // Ensure event data exists
    if (!event || !event.date) {
        return null;
    }

    const isExpanded = expandedEventIndex === `${monthIndex}-${eventIndex}`;

    return (
        <Box>
            <UpcomingEventListItem onClick={() => handleToggle(`${monthIndex}-${eventIndex}`)}>
                <UpcomingEventDateBox>
                    <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                        {event.date.dayNumber}
                    </Typography>
                    <Typography variant="caption" sx={{ textTransform: 'uppercase' }}>
                        {event.date.month}
                    </Typography>
                </UpcomingEventDateBox>
                <Typography variant="body1" sx={{ flexGrow: 1 }}>
                    {event.title}
                </Typography>
                <Typography variant="body2">
                    {event.date.dayAndTime}
                </Typography>
                {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </UpcomingEventListItem>
            <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                <Box sx={{ paddingLeft: 2, paddingRight: 2, paddingBottom: 2 }}>
                    <Typography variant="body2">{event.description}</Typography>
                </Box>
            </Collapse>
            {/* Conditionally render the divider for all but the last event */}
            {eventIndex < totalEvents - 1 && <Divider />} {/* Divider will render for all items except the last */}
        </Box>
    );
};
