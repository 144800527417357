import React, { useState } from 'react';
import { PageTitleBar } from '../../components/PageTitleBar';
import { MAUpcomingEventsData } from '../../helpers/MAUpcomingEventsData';
import { MainUpcomingEventsBox, FeaturedUpcomingEventBox, UpcomingEventTypography, UpcomingEventListBox } from '../../styles/useStyles';
import { FeaturedEventCard } from '../../components/UpcomingEvents/FeaturedEventCard';
import { EventList } from '../../components/UpcomingEvents/EventList';


export const MAUpcomingEvents = () => {

    const [expandedEventIndex, setExpandedEventIndex] = useState(null);

    const handleToggle = (index) => {
        setExpandedEventIndex(expandedEventIndex === index ? null : index);
    };
    return (
        <MainUpcomingEventsBox>
            <PageTitleBar title=" MA Upcoming Events" icon="🔖" />
            {/* Featured Event Section */}
            <FeaturedUpcomingEventBox>
                <UpcomingEventTypography variant="h6" gutterBottom>
                    Next MA Event
                </UpcomingEventTypography>
                <FeaturedEventCard event={MAUpcomingEventsData.featuredEvent} />
            </FeaturedUpcomingEventBox>
            {/* Upcoming Events List */}
            <UpcomingEventListBox>
                {MAUpcomingEventsData.upcomingEvents.map((monthData, monthIndex) => (
                    <EventList
                        key={monthIndex}
                        monthData={{ ...monthData, monthIndex }}
                        expandedEventIndex={expandedEventIndex}
                        handleToggle={handleToggle}
                    />
                ))}
            </UpcomingEventListBox>
        </MainUpcomingEventsBox>
    );
};
