import React, { useState } from 'react';
import { PageTitleBar } from '../components/PageTitleBar';
import { upcomingEventsData } from '../helpers/upcomingEventsData';
import { MainUpcomingEventsBox, FeaturedUpcomingEventBox, UpcomingEventTypography, UpcomingEventListBox } from '../styles/useStyles';
import { FeaturedEventCard } from '../components/UpcomingEvents/FeaturedEventCard';
import { EventList } from '../components/UpcomingEvents/EventList';

export const UpcomingEvents = () => {
    const [expandedEventIndex, setExpandedEventIndex] = useState(null);

    const handleToggle = (index) => {
        setExpandedEventIndex(expandedEventIndex === index ? null : index);
    };

    return (
        <MainUpcomingEventsBox>
            <PageTitleBar title="Upcoming Events" icon="🔖" />
            {/* Featured Event Section */}
            <FeaturedUpcomingEventBox>
                <UpcomingEventTypography variant="h6" gutterBottom>
                    Next event
                </UpcomingEventTypography>
                <FeaturedEventCard event={upcomingEventsData.featuredEvent} />
            </FeaturedUpcomingEventBox>
            {/* Upcoming Events List */}
            <UpcomingEventListBox>
                {upcomingEventsData.upcomingEvents.map((monthData, monthIndex) => (
                    <EventList
                        key={monthIndex}
                        monthData={{ ...monthData, monthIndex }}
                        expandedEventIndex={expandedEventIndex}
                        handleToggle={handleToggle}
                    />
                ))}
            </UpcomingEventListBox>
        </MainUpcomingEventsBox>
    );
};