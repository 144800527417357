import React, { useState } from 'react';
import { List, ListItem, ListItemText, ListSubheader, Collapse, Badge, Tooltip } from '@mui/material';
import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material';
import { SidebarCustomListItemButton, SidebarLastListItemButton } from '../../styles/useStyles';
import { useNavigate, useLocation } from 'react-router-dom';
import { initialViews } from "../../helpers/initialViews";
import { allStates } from '../../helpers/allStates';
import { checkAndRefreshToken } from '../../helpers/checkAndRefreshToken';

export const SidebarContent = ({ newPostsCount }) => {
    const navigate = useNavigate();
    const location = useLocation();

    const userType = window.localStorage.getItem('userType');
    const userState = window.localStorage.getItem('state');
    const accessToken = window.localStorage.getItem('iat');
    const isOnAbeePage = location.pathname === '/abee';

    // Disable other items if on the Abee page and not logged in
    const disabled = !accessToken && isOnAbeePage;

    // Modify the initialViews based on userType and state
    const modifiedViews = initialViews.map((view, index) => {
        if (index === 0) { // Adding state-related views to the "Stay Connected" section
            let updatedItems = [...view.items];

            // Add "MA Past Events" for Massachusetts and Inlier team users
            if (userState === 'Massachusetts' || userType === "true") {
                updatedItems.splice(3, 0, {
                    text: "MA Past Events",
                    icon: "⏮️",
                    path: "/ma-past-events"
                });
            }

            // Add "MA Upcoming Events" for Massachusetts and Inlier team users
            if (userState === 'Massachusetts' || userType === "true") {
                updatedItems.splice(2, 0, {
                    text: "MA Upcoming Events",
                    icon: "🔖",
                    path: "/ma-upcoming-events"
                });
            }

            return {
                ...view,
                items: userType === "true"
                    ? [...updatedItems, ...allStates.map(state => ({
                        text: state,
                        icon: "📍",
                        path: `/state/${state.replace(/\s+/g, '').toLowerCase()}` // Replace spaces and convert to lowercase
                    }))]
                    : [...updatedItems, {
                        text: userState,
                        icon: "📍",
                        path: `/state/${userState?.replace(/\s+/g, '').toLowerCase()}` // Replace spaces and convert to lowercase 
                    }]
            };
        }
        return view;
    });

    const [open, setOpen] = useState(modifiedViews.map(() => true));

    const handleToggle = (index) => {
        setOpen((prev) => {
            const newOpen = [...prev];
            newOpen[index] = !newOpen[index];
            return newOpen;
        });
    };

    const handleNavigation = async (path) => {
        try {
            if (path === '/feedback') {
                window.open('https://forms.gle/XYyb8wfj9RsLqPfM9', '_blank');
            } else if (path === '/help-center') {
                window.open('https://forms.gle/P7bnCjEFwLrLxrmr8', '_blank');
            } else if (path === '/office-hours') {
                window.open('https://calendly.com/orly-inlier', '_blank');
            } else {
                const isTokenValid = await checkAndRefreshToken();
                if (isTokenValid) {
                    navigate(path); // Navigate to internal paths
                } else {
                    navigate('/');  // Redirect to login if token is invalid
                }
            }
        } catch (error) {
            console.error('Error during navigation:', error); // Log any unexpected errors
            navigate('/'); // Fallback to login in case of error
        }
    };

    const totalItems = modifiedViews.reduce((sum, view) => sum + view.items.length, 0);
    let itemCount = 0;

    // Map groupId to postCount for quick lookup
    const postCounts = Array.isArray(newPostsCount)
        ? newPostsCount.reduce((map, group) => {
            map[group.name] = group.postCount;
            return map;
        }, {})
        : {}; // Default to an empty object if newPostsCount is not an array

    return (
        <List>
            {modifiedViews.map((view, index) => (
                <div key={index}>
                    <SidebarCustomListItemButton onClick={() => handleToggle(index)} disabled={disabled}>
                        <ListSubheader component="div">{view.subheader}</ListSubheader>
                        {open[index] ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </SidebarCustomListItemButton>
                    <Collapse in={open[index]} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {view.items.map((item, itemIndex) => {
                                itemCount++;
                                const isActive = location.pathname === item.path;
                                const isLastItem = itemCount === totalItems;
                                const isItemDisabled = disabled && item.path !== '/abee';
                                const postCount = postCounts[item.text] || 0;
                                return (
                                    <ListItem key={itemIndex} disablePadding>
                                        {isLastItem ? (
                                            <SidebarLastListItemButton
                                                onClick={() => !isItemDisabled && handleNavigation(item.path)}  // Handle navigation
                                                active={isActive}
                                                disabled={isItemDisabled}
                                            >
                                                <ListItemText
                                                    primary={
                                                        <span style={{ color: isActive ? 'white' : 'inherit' }}>
                                                            {item.icon}&nbsp;&nbsp;&nbsp;{item.text}
                                                        </span>
                                                    }
                                                />
                                                {postCount > 0 && (
                                                    <Tooltip title="Recent Posts" arrow>
                                                        <Badge
                                                            badgeContent={postCount}
                                                            sx={{
                                                                '& .MuiBadge-badge': {
                                                                    backgroundColor: 'gray',
                                                                    color: 'white',
                                                                    fontSize: '0.75rem',
                                                                    height: '16px',
                                                                    minWidth: '16px',
                                                                },
                                                            }}
                                                        />
                                                    </Tooltip>
                                                )}
                                            </SidebarLastListItemButton>
                                        ) : (
                                            <SidebarCustomListItemButton
                                                onClick={() => !isItemDisabled && handleNavigation(item.path)}  // Handle navigation
                                                active={isActive}
                                                disabled={isItemDisabled}
                                            >
                                                <ListItemText
                                                    primary={
                                                        <span style={{ color: isActive ? 'white' : 'inherit' }}>
                                                            {item.icon}&nbsp;&nbsp;&nbsp;{item.text}
                                                        </span>
                                                    }
                                                />
                                                {postCount > 0 && (
                                                    <Tooltip title="Recent Posts" arrow>
                                                        <Badge
                                                            badgeContent={postCount}
                                                            sx={{
                                                                '& .MuiBadge-badge': {
                                                                    backgroundColor: 'lightgray',
                                                                    color: 'black',
                                                                    fontSize: '0.75rem',
                                                                    height: '16px',
                                                                    minWidth: '16px',
                                                                },
                                                            }}
                                                        />
                                                    </Tooltip>
                                                )}
                                            </SidebarCustomListItemButton>
                                        )}
                                    </ListItem>
                                );
                            })}
                        </List>
                    </Collapse>
                </div>
            ))}
        </List>
    );
};
