import { ForumPage } from '../../components/ForumPage';
import { groupUrltoId } from '../../helpers/groupUrltoId';

export const Identification = ({ handleNewPostCount }) => {

    const groupId = groupUrltoId['/identification'];

    return (
        <ForumPage title="Identification" icon="🟡" groupId={groupId} path="/identification" handleNewPostCount={handleNewPostCount} />
    )

};