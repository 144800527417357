import React, { useState } from 'react';
import { Typography, Link, Collapse, Button } from '@mui/material';
import {
    UpcomingEventCard,
    UpcomingEventCardBox,
    UpcomingEventCardContent
} from '../../styles/useStyles';

export const FeaturedEventCard = ({ event }) => {
    const [showDetails, setShowDetails] = useState(false);

    const handleToggleDetails = () => {
        setShowDetails((prev) => !prev);
    };

    return (
        <UpcomingEventCard>
            <UpcomingEventCardBox
                component="img"
                alt="Featured Event"
                src={event.image}
            />
            <UpcomingEventCardContent>
                <Typography variant="h5" gutterBottom>
                    {event.title}
                </Typography>
                <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold' }}>
                    {event.date.dayAndTime}
                </Typography>
                <Typography variant="body1" gutterBottom>
                    {event.description}
                </Typography>

                {/* Zoom Join Link */}
                <Typography
                    variant="body1"
                    component="div"
                    sx={{ mt: 2, textAlign: 'center' }}
                >
                    <Link
                        href={event.zoomLink}
                        target="_blank"
                        rel="noopener"
                        sx={{
                            fontWeight: 'bold',
                            fontSize: '1.2rem',
                            color: '#1976d2',
                            textDecoration: 'none',
                            padding: '10px 20px',
                            border: '2px solid #1976d2',
                            borderRadius: '8px',
                            display: 'inline-block',
                            '&:hover': {
                                backgroundColor: '#1976d2',
                                color: '#fff',
                                textDecoration: 'none',
                            }
                        }}
                    >
                        Join Zoom Meeting
                    </Link>
                </Typography>

                {/* Toggle Zoom Details */}
                <Typography variant="body2" sx={{ mt: 2, textAlign: 'center' }}>
                    <Button
                        onClick={handleToggleDetails}
                        size="small"
                        sx={{ color: '#1976d2', textTransform: 'none' }}
                    >
                        {showDetails ? 'Hide Zoom Meeting Details' : 'Zoom Meeting Details'}
                    </Button>
                </Typography>

                {/* Zoom Meeting Details (Collapsible) */}
                <Collapse in={showDetails}>
                    <Typography
                        variant="body2"
                        sx={{ mt: 2, whiteSpace: 'pre-line', fontSize: '0.9rem' }}
                    >
                        {event.zoomDetails}
                    </Typography>
                </Collapse>
            </UpcomingEventCardContent>
        </UpcomingEventCard>
    );
};
