import { Quill } from 'react-quill';

export const sanitizeLink = () => {
    const Link = Quill.import('formats/link');

    // Save the original create method
    const originalCreate = Link.create;

    Link.sanitize = function (url) {
        // If the URL doesn't start with a protocol, add "http://"
        if (url && !/^https?:\/\//.test(url)) {
            url = 'http://' + url;
        }
        return url;
    };

    // Override the create method to add target="_blank" and rel="noopener noreferrer"
    Link.create = function (value) {
        const node = originalCreate.call(this, value);

        // Set the link to open in a new tab
        node.setAttribute('target', '_blank');
        node.setAttribute('rel', 'noopener noreferrer nofollow');

        // Optionally add a custom class for styling
        node.classList.add('external-link');

        return node;
    };

    Quill.register(Link, true);
};