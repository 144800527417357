import React, { useState, useEffect } from 'react';
import { Typography, Divider, ListItemIcon } from '@mui/material';
import { UserIconButton, CustomAvatar, CustomMenuItem, CustomMenu } from '../../styles/useStyles';
import { useNavigate } from 'react-router-dom';
import { Settings, LogOut } from 'react-feather';

export const UserAvatar = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [avatarColor, setAvatarColor] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const storedFirstName = window.localStorage.getItem('firstName');
        const storedLastName = window.localStorage.getItem('lastName');
        const storedColor = window.localStorage.getItem('avatarColor');

        if (storedFirstName) {
            setFirstName(storedFirstName);
        }
        if (storedLastName) {
            setLastName(storedLastName);
        }
        if (storedColor) {
            setAvatarColor(storedColor);
        }
    }, []);

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        window.localStorage.clear();
        navigate('/');
    };

    const handleNavigateToSettings = () => {
        navigate('/settings'); // Navigate to the Settings page
        handleMenuClose();     // Close the menu after navigation
    };

    const getInitials = (firstName, lastName) => {
        return `${firstName.charAt(0).toUpperCase()}${lastName.charAt(0).toUpperCase()}`;
    };

    return (
        <div>
            <UserIconButton onClick={handleMenuOpen}>
                <CustomAvatar sx={{ backgroundColor: avatarColor }}>
                    {getInitials(firstName, lastName)}
                </CustomAvatar>
            </UserIconButton>
            <CustomMenu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                <Typography variant="subtitle1" style={{ padding: '8px 16px' }}>
                    {`${firstName} ${lastName}`}
                </Typography>
                <Divider />
                <CustomMenuItem onClick={handleNavigateToSettings}>
                    <ListItemIcon>
                        <Settings size={20} />
                    </ListItemIcon>
                    Settings
                </CustomMenuItem>
                <CustomMenuItem onClick={handleLogout}>
                    <ListItemIcon>
                        <LogOut size={20} />
                    </ListItemIcon>
                    Logout
                </CustomMenuItem>
            </CustomMenu>
        </div>
    );
};
