import React, { useState } from 'react';
import { TextField, Button, IconButton, InputAdornment, Box, CircularProgress, Typography } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useNavigate, useLocation } from 'react-router-dom';
import { api } from '../../helpers/axios';
import { toast } from 'react-toastify';
import logoBlack from '../../styles/images/logoBlack.png';

const colors = ['#679F37', '#FF5733', '#33A8FF', '#F39C12', '#8E44AD', '#27AE60'];
const getRandomColor = () => colors[Math.floor(Math.random() * colors.length)];

export const LoginForm = ({ loading, setLoading, setForgotPasswordOpen }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    const handleClickShowPassword = () => setShowPassword(!showPassword);

    const handleLogin = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const response = await api.post(`/auth/login`, { email, password }, { withCredentials: true });
            const { user, accessToken } = response.data.message;
            const avatarColor = user.avatarColor || getRandomColor();

            // Store the necessary data in localStorage
            window.localStorage.setItem('iat', accessToken);
            window.localStorage.setItem('iun', user.email);
            window.localStorage.setItem('userType', user.isInlierTeam);
            window.localStorage.setItem('state', user.state);
            window.localStorage.setItem('avatarColor', avatarColor);
            window.localStorage.setItem('firstName', user.firstName);
            window.localStorage.setItem('lastName', user.lastName);

            // If user doesn't have an avatar color, update it
            if (!user.avatarColor) {
                await api.put(`/users/profile`, { avatarColor }, {
                    withCredentials: true,
                    headers: { authorization: `Bearer ${accessToken}` }
                });
            }

            // Check if the user needs to change their password
            if (user.promptPassword) {
                // If promptPassword is true, navigate to onboarding password change page
                navigate('/onboarding-password-change');
            } else {
                // Otherwise, proceed to the regular start page or another redirect
                const redirectTo = new URLSearchParams(location.search).get('redirect') || '/start-here';
                window.location.href = redirectTo; // Forces a page reload
            }

        } catch (err) {
            setLoading(false);
            toast.error('Incorrect username or password.');
            window.localStorage.clear();
            navigate('/');
        }
    };

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '100vh',
                flexDirection: 'column',
            }}
        >
            {/* Logo */}
            <Box
                component="img"
                src={logoBlack}
                alt="Logo"
                sx={{
                    width: '150px',
                    marginBottom: '1.5rem',
                }}
            />

            {/* Form */}
            <Box
                sx={{
                    width: '100%',
                    maxWidth: '400px',
                    padding: '2rem',
                    border: '1px solid #ddd',
                    borderRadius: '8px',
                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                }}
            >
                <form onSubmit={handleLogin}>
                    <TextField
                        label="Email"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        fullWidth
                        margin="dense"
                        sx={{ marginBottom: '1rem' }}
                    />
                    <TextField
                        label="Password"
                        type={showPassword ? 'text' : 'password'}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        fullWidth
                        margin="dense"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                        sx={{ marginBottom: '1rem' }}
                    />
                    <Button
                        variant="contained"
                        fullWidth
                        type="submit"
                        sx={{
                            backgroundColor: loading ? '#ccc' : '#4A90E2',
                            padding: '0.8rem',
                            fontSize: '1rem',
                            '&:hover': { backgroundColor: '#357ABD' },
                            transition: 'background-color 0.3s ease',
                            marginBottom: '1rem'
                        }}
                        disabled={loading}
                    >
                        {loading ? <CircularProgress size={24} /> : 'Login'}
                    </Button>
                </form>

                {/* Forgot Password Link */}
                <Typography
                    variant="caption"
                    color="primary"
                    onClick={() => setForgotPasswordOpen(true)} // Trigger modal open
                    sx={{
                        cursor: 'pointer',
                        display: 'block',
                        textAlign: 'center',
                        marginTop: '1rem',
                        '&:hover': { textDecoration: 'underline' }
                    }}
                >
                    Forgot Password?
                </Typography>
            </Box>
        </Box>
    );
};
