import { ForumPage } from '../../components/ForumPage';
import { groupUrltoId } from '../../helpers/groupUrltoId';

export const TraumaSupport = ({ handleNewPostCount }) => {

    const groupId = groupUrltoId['/trauma-support'];

    return (
        <ForumPage title="Trauma Support" icon="🟡" groupId={groupId} path="/trauma-support" handleNewPostCount={handleNewPostCount} />
    )

};